<template>
   <RouterLink to="/">Etusivu</RouterLink> &nbsp;|&nbsp; 
   <RouterLink to="/order">Tilaa palvelu</RouterLink> &nbsp;|&nbsp; 
   <RouterLink to="/trial">Tutustu maksutta</RouterLink> &nbsp;|&nbsp; 
   <RouterLink to="/terms">Käyttöehdot</RouterLink> &nbsp;|&nbsp; 
   <RouterLink to="/privacy">Tietosuojaseloste</RouterLink> &nbsp;|&nbsp; 
   © <RouterLink to="/valmia">Valmia Oy</RouterLink>
    <br />
    <br />
    <br />
</template>
<script>
export default {
    name: 'FooterNav',
    data() {
        return {
            active: false,
        }
    }
}
</script>