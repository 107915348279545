<template>
    <table class="calc-table">
        <tr>
            <td>
                <strong>{{ capitalize(T('nimi')) }}</strong>
            </td>
            <td>
                <o-input class="input" v-model="guardian.name" @blur="update" />
            </td>
        </tr>
        <tr class="valign-top">
            <td colspan="2">
                <div class="container">
                    <div class="cell">
                        <strong>{{ capitalize(T('housemate')) }}</strong>
                        <br/>
                        <span @click="showHouseMateInfo = !showHouseMateInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showHouseMateInfo">Näytä ohje</small><small v-if="showHouseMateInfo">Piilota ohje</small></span>
                    </div>
                    <div class="cell">   
                        <o-select v-model="guardian.housemate" @change="update">
                            <option value="0">Ei</option>
                            <option value="1">Avopuoliso</option>
                            <option value="2">Aviopuoliso</option>
                        </o-select>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="showHouseMateInfo" v-html="T('info_housemate')" class="info-paragraph">
                    </div>
                </transition>
            </td>
        </tr>
        <tr v-if="guardian.housemate && guardian.housemate === 2" class="valign-top">
            <td colspan="2">
                <div class="container">
                    <div class="cell">
                        <strong>{{ capitalize(T('married_housemate_support')) }}</strong>
                        <br/>
                        <span @click="showMarriedInfo = !showMarriedInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showMarriedInfo">Näytä ohje</small><small v-if="showMarriedInfo">Piilota ohje</small></span>
                    </div>
                    <div class="cell">
                        <o-select v-model="guardian.housemate_married_support_percentage" @change="update">
                            <option value="0">Ei</option>
                            <option value="50">Osittain (50%)</option>
                            <option value="100">Kyllä (100%)</option>
                        </o-select>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="showMarriedInfo" v-html="T('info_married_housemate_support')" class="info-paragraph">
                    </div>
                </transition>
            </td>
        </tr>
        <tr v-if="guardian.housemate && guardian.housemate === 1" class="valign-top">
            <td colspan="2">
                <div class="container">
                    <div class="cell">
                        <strong>{{ capitalize(T('unmarried_housemate_support')) }}</strong>
                        <br/>
                        <span @click="showUnMarriedInfo = !showUnMarriedInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showUnMarriedInfo">Näytä ohje</small><small v-if="showUnMarriedInfo">Piilota ohje</small></span>
                    </div>
                    <div class="cell">
                        <o-select v-model="guardian.housemate_unmarried_support_percentage" @change="update">
                            <option value="0">Ei</option>
                            <option value="50">Osittain (50%)</option>
                            <option value="100">Kyllä (100%)</option>
                        </o-select>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="showUnMarriedInfo" v-html="T('info_unmarried_housemate_support')" class="info-paragraph">
                    </div>
                </transition>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <strong>{{ T('children_list') }}</strong>
                <div class="">
                    <table class="guardians-child-list">
                        <tr>
                            <th
                                style="padding: 0px; padding-top: 10px !important; margin: 0px !important; border: none !important;">
                                {{ T('name') }}</th>
                            <th
                                style="padding: 0px; padding-top: 10px !important; margin: 0px !important; padding-bottom:-20px; border: none !important;">
                                {{ T('age') }}</th>
                            <th
                                style="padding: 0px; padding-top: 10px !important; margin: 0px !important; padding-bottom:-20px; border: none !important;">
                            </th>
                        </tr>
                        <tr v-for="housedChild in guardian.housed_children_list" :key="'hc-' + housedChild.code">
                            <td style="padding: 0px !important; border: none !important;"> {{ housedChild.name }}</td>
                            <td style="padding: 0px !important;  border: none !important;">{{ housedChild.age }}v</td>
                            <td style="padding: 0px !important;  border: none !important;"
                                v-if="housedChild.alternating_weeks == 1 && housedChild.child_benefit">{{
                    T('altweeks_benefits_to_parent') }}</td>
                            <td style="padding: 0px !important;  border: none !important;"
                                v-if="housedChild.alternating_weeks == 1 && !housedChild.child_benefit">{{
                    T('altweeks_benefits_to_other_parent') }}</td>
                            <td style="padding: 0px !important;  border: none !important;"
                                v-if="housedChild.alternating_weeks == 0"></td>
                        </tr>
                    </table>
                </div>
            </td>
        </tr>

        <tr>
            <td colspan="2">
                <div class="container">
                    <div class="cell">
                        <strong>{{ capitalize(T('other_children')) }}</strong>
                        <br/>
                        <span @click="showOtherChildInfo = !showOtherChildInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showOtherChildInfo">Näytä ohje</small><small v-if="showOtherChildInfo">Piilota ohje</small></span>
                    </div>
                    <div class="cell"> <input @change="update" type="radio" :value="0" v-model="has_other_children"
                            :name="'hoc-' + guardian.code" :id="has_other_children" /><label for="has_other_children">{{
                    T('no')
                }}</label>
                        <input @change="update" type="radio" :value="1" v-model="has_other_children"
                            :name="'hoc-' + guardian.code" id="has_other_children" /><label for="has_other_children">{{
                    T('yes')
                }}</label><br />
                    </div>
                </div>

                <transition name="fade">
                    <div v-if="showOtherChildInfo" v-html="T('info_other_children')" class="info-paragraph">
                    </div>
                </transition>
            </td>
        </tr>
        <tr v-if="has_other_children">
            <td class="breakdown-td" colspan="2">
                <transition name="fade">
                    <div style="padding-top:0" class="breakdown-wrapper">
                       <!--
                        <div class="container">
                            <div class="cell">
                                <strong>{{ T('name') }}</strong><br>
                                <input type="text"/>
                                </div>
                                <div class="cell">
                                <strong>{{ T('name') }}</strong><br>
                                <input type="text"/>
                                </div>
                                <div class="cell">
                                <strong>{{ T('name') }}</strong><br>
                                <input type="text"/>
                                </div>

                        </div>
                        VAKIOT:<br>
                        - Nimi<br>
                        - Ikä<br>
                        <br>
                       JOS ASUU KUMPPANIN KANSSA:<br>
                       - Onko asuinkumppani vanhempi<br>
                       -- Kyllä<br>
                       -- EI<br>
                       <br>
                       ---- KUN EI TAI KUMPPANI EI OLE VANHEMPI<br>
                       ------ Asuminen: normaali / vuoroasuminen<br>
                       -------- Jos normaaliasuminen, niin näytetään vähennettävän elatusavun/tuen kenttä<br>
                       -------- Jos vuoroasuminen niin kystyään saako lapsilisän<br>
                       -------- Jos vuoroasuminen niin saako/maksaako elatusapua/tukea<br>
                       ---------- Jos saa/maksaa niin kysytään määrä<br>
                          -->
                        <table class="add-new-breakdown" v-if="has_other_children">
                            <OtherChild :houseMate="guardian.housemate" :other-children="guardian.other_children"
                            :guardian-code="guardian.code" />
                        </table>
                    </div>
                </transition>
            </td>
        </tr>

        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong>{{ capitalize(T('income')) }}</strong>
                        <br/>
                        <span @click="showIncomeInfo = !showIncomeInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showIncomeInfo">Näytä ohje</small><small v-if="showIncomeInfo">Piilota ohje</small></span>
    
                <transition name="fade">
                    <div v-if="showIncomeInfo" v-html="T('info_income')" class="info-paragraph"></div>
                </transition>
                <BreakDown :pre-open="true" :entity="guardian" type="income" entity-type="guardian" field="income"
                    :static-total="safeSingleParentBenefit" static-name="singleParentBenefit"
                    :static-data="guardian.housemate" />
            </td>
        </tr>
        <tr v-if="1 == 2" class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong @click="showChildBenetRiseInfo = !showChildBenetRiseInfo" class="tooltip-hl">{{
                    capitalize(T('child_benefit_rise_deduction')) }}<o-icon class="appBlue" pack="fas"
                        icon="info-circle">
                    </o-icon></strong>
                <transition name="fade">
                    <div v-if="showChildBenetRiseInfo" v-html="T('info_child_benefit_rise_deduction')"
                        class="info-paragraph"></div>
                </transition>
                <BreakDown :pre-open="true" :entity="guardian" type="share" entity-type="guardian"
                    field="child_benefit_rise_deduction" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong>{{ capitalize(T('housing_costs')) }}</strong>
                        <br/>
                        <span @click="showHousingCostsInfo = !showHousingCostsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showHousingCostsInfo">Näytä ohje</small><small v-if="showHousingCostsInfo">Piilota ohje</small></span>
    
                <transition name="fade">
                    <div v-if="showHousingCostsInfo" v-html="T('info_housing_costs')" class="info-paragraph"></div>
                </transition>
                <div v-if="guardian.type == 'close' && !alternatingWeeks" style="margin-top:10px">
                    <input v-if="1 == 2" type="checkbox" v-model="housing_costs_to_children" @change="update"
                        id="housingcoststochildren" /><label v-if="1 == 2" for="housingcoststochildren"
                        style="margin-right:0 !important">{{
                    capitalize(T('housing_costs_to_children')) }}</label>
                    <o-icon v-if="1 == 2" @click="showHousingCostsToChildrenInfo = !showHousingCostsToChildrenInfo"
                        class="appBlue" pack="fas" icon="info-circle">
                    </o-icon>
                    <transition name="fade">
                        <div v-if="showHousingCostsToChildrenInfo" v-html="T('info_housing_costs_to_children')"
                            class="info-paragraph"></div>
                    </transition>
                </div>
                <BreakDown :pre-open="true" :entity="guardian" type="cost" entity-type="guardian"
                    field="housing_costs" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showHousingBenefitFields = !showHousingBenefitFields">{{ capitalize(T('housing_benefit')) }} &nbsp;
                <i v-if="!showHousingBenefitFields" class="fa-solid fa-circle-plus"></i><i v-if="showHousingBenefitFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showHousingBenefitFields" @click="showHousingBenefitInfo = !showHousingBenefitInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showHousingBenefitInfo">Näytä ohje</small><small v-if="showHousingBenefitInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showHousingBenefitInfo" v-html="T('info_housing_benefit')" class="info-paragraph"></div>
                </transition>
                <BreakDown v-if="showHousingBenefitFields" :pre-open="true" :entity="guardian" type="income"
                    entity-type="guardian" field="housing_benefit" />
            </td>
        </tr>
        <tr>
            <td colspan="2" class="breakdown-td">
                <strong class="tooltip-hl" @click="showKMCalcFields = !showKMCalcFields">{{ capitalize(T('commuting_expenses_km')) }} &nbsp;
                <i v-if="!showKMCalcFields" class="fa-solid fa-circle-plus"></i><i v-if="showKMCalcFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showKMCalcFields" @click="showCommutingExpensesKmInfo = !showCommutingExpensesKmInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showCommutingExpensesKmInfo">Näytä ohje</small><small v-if="showCommutingExpensesKmInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showCommutingExpensesKmInfo" v-html="T('info_commuting_expenses_km')"
                        class="info-paragraph">
                    </div>
                </transition>
                <div class="total-breakdown">{{ $EUR(guardian.commuting_expenses_km) }}€/kk</div>
                <div class="inner-fw-table-container" v-if="showKMCalcFields">
                    <table>
                        <tr v-if="guardian.commuting_expenses_before_tax_deduction > 0">
                            <td><strong>Auton vuosikulut</strong></td>
                            <td>{{ $EUR(guardian.commuting_expenses_before_tax_deduction) }}€/v</td>
                        </tr>
                        <tr v-if="guardian.commuting_expenses_km_tax_deduction > 0">
                            <td><strong>Omavastuu</strong></td>
                            <td>{{ $EUR(guardian.commuting_expenses_km_tax_deduction_own_share) }}€/v</td>
                        </tr>
                        <tr v-if="guardian.commuting_expenses_km_tax_deduction > 0">
                            <td><strong>Verovähennys</strong></td>
                            <td>{{ $EUR(guardian.commuting_expenses_km_tax_deduction) }}€/kk</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>km/yhteen suuntaan/päivä</strong>
                            </td>
                            <td>
                                <o-input class="input" v-model="guardian.oneway_km_to_work" @blur="update" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Edestakaisien matkojen lkm/vko</strong>
                            </td>
                            <td>
                                <o-select v-model="guardian.commuting_days_per_week" @change="update">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </o-select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Työmatkakukausia vuodessa</strong>
                            </td>
                            <td>
                                <o-select v-model="guardian.commuting_months_per_year" @change="update">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </o-select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Vero %</strong>
                            </td>
                            <td>
                                <o-input class="input" v-model="guardian.tax_rate" @blur="update" />
                            </td>
                        </tr>
                    </table>
                </div>
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showCommunicatingExpensesFields = !showCommunicatingExpensesFields">{{ capitalize(T('commuting_expenses')) }} &nbsp;
                <i v-if="!showCommunicatingExpensesFields" class="fa-solid fa-circle-plus"></i><i v-if="showCommunicatingExpensesFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showCommunicatingExpensesFields" @click="showCommunicatingExpensesInfo = !showCommunicatingExpensesInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showCommunicatingExpensesInfo">Näytä ohje</small><small v-if="showCommunicatingExpensesInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showCommunicatingExpensesInfo" v-html="T('info_commuting_expenses')"
                        class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showCommunicatingExpensesFields" :entity="guardian" type="cost" entity-type="guardian" field="commuting_expenses" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showSpecialMedicalExpensesFields = !showSpecialMedicalExpensesFields">{{ capitalize(T('special_medical_expenses')) }} &nbsp;
                <i v-if="!showSpecialMedicalExpensesFields" class="fa-solid fa-circle-plus"></i><i v-if="showSpecialMedicalExpensesFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showSpecialMedicalExpensesFields" @click="showSpecialMedicalExpensesInfo = !showSpecialMedicalExpensesInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showSpecialMedicalExpensesInfo">Näytä ohje</small><small v-if="showSpecialMedicalExpensesInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showSpecialMedicalExpensesInfo" v-html="T('info_special_medical_expenses')"
                        class="info-paragraph"></div>
                </transition>
                <BreakDown v-if="showSpecialMedicalExpensesFields" :pre-open="true" :entity="guardian" type="cost"
                    entity-type="guardian" field="special_medical_expenses" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showStudentLoanCalcFields = !showStudentLoanCalcFields">{{ capitalize(T('student_loan_costs')) }} &nbsp;
                <i v-if="!showStudentLoanCalcFields" class="fa-solid fa-circle-plus"></i><i v-if="showStudentLoanCalcFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showStudentLoanCalcFields" @click="showStudentLoanCostsInfo = !showStudentLoanCostsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showStudentLoanCostsInfo">Näytä ohje</small><small v-if="showStudentLoanCostsInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showStudentLoanCostsInfo" v-html="T('info_student_loan_costs')" class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showStudentLoanCalcFields" :pre-open="true" :entity="guardian" type="cost"
                    entity-type="guardian" field="student_loan_costs" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong>{{ capitalize(T('other_child_support_obligation')) }}</strong>
                <br/><span @click="showOtherChildSupportObligationsInfo = !showOtherChildSupportObligationsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showOtherChildSupportObligationsInfo">Näytä ohje</small><small v-if="showOtherChildSupportObligationsInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showOtherChildSupportObligationsInfo" v-html="T('info_other_child_support_obligation')"
                        class="info-paragraph"></div>
                </transition>
                <BreakDown :pre-open="true" :entity="guardian" type="cost" entity-type="guardian"
                    field="other_child_support_obligation" :static-total="guardian.otherChildSupportTotalCost"
                    static-name="otherChildren" :static-data="guardian.otherChildSupportTable" />
            </td>
        </tr>
        <tr class="valign-top">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showVisitationExpenseCalcFields = !showVisitationExpenseCalcFields">{{ capitalize(T('visitation_expenses')) }} &nbsp;
                <i v-if="!showVisitationExpenseCalcFields" class="fa-solid fa-circle-plus"></i><i v-if="showVisitationExpenseCalcFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showVisitationExpenseCalcFields" @click="showVisitationExpensesInfo = !showVisitationExpensesInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showVisitationExpensesInfo">Näytä ohje</small><small v-if="showVisitationExpensesInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showVisitationExpensesInfo" v-html="T('info_visitation_expenses')"
                        class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showVisitationExpenseCalcFields" :pre-open="false" :entity="guardian" type="cost"
                    entity-type="guardian" field="visitation_expenses" />
            </td>
        </tr>
    </table>
</template>
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
import BreakDown from './Breakdown.vue';
import OtherChild from './OtherChild.vue';
export default {
    name: 'GuardianForm',
    components: {
        BreakDown,
        OtherChild
    },
    props: [
        'guardianCode',
        'alternatingWeeks'
    ],
    computed: {
        ...mapWritableState(useCalculationStore, { calculation: 'calculation', calculatedFields: 'calculatedFields', token: 'token' }),
        guardian() {
            return this.calculation.guardians.find(guardian => guardian.code == this.guardianCode);
        },
        housing_costs_to_children: {
            get() {
                //console.log("getter of: " + this.guardian.name, this.guardian.housing_costs_to_children);
                return Boolean(this.guardian.housing_costs_to_children);
            },
            set(newValue) {
                //console.log("setter of:" + this.guardian.name, newValue);
                if (newValue)
                    this.guardian.housing_costs_to_children = 1;
                else
                    this.guardian.housing_costs_to_children = 0;
                this.update();
            }
        },
        has_other_children: {
            get() {
                //console.log('getter', this.guardian.has_other_children);
                return this.guardian.has_other_children;
            },
            set(newValue) {
                //console.log('setter', this.guardian.has_other_children, newValue);
                this.guardian.has_other_children = newValue ? 1 : 0;
            }
        },
        safeSingleParentBenefit() {
            return this.guardian.single_parent_benefit ? this.guardian.single_parent_benefit : 0;
        }
    },
    data() {
        return {
            showMarriedInfo:false,
            showUnMarriedInfo:false,
            showCommunicatingExpensesFields:false,
            showHousingBenefitFields: false,
            showSpecialMedicalExpensesFields: false,
            showChildBenetRiseCalcFields: false,
            showCommunicatingExpenseCalcFields: false,
            showVisitationExpenseCalcFields: false,
            showStudentLoanCalcFields: false,
            showKMCalcFields: false,
            showOtherChildInfo: false,
            showOwnChildrenCountInfo: false,
            showOwnUnder17yoInfo: false,
            showOwnUnder3yoInfo: false,
            showHouseMateInfo: false,
            showIncomeInfo: false,
            showChildBenetRiseInfo: false,
            showHousingCostsInfo: false,
            showHousingCostsToChildrenInfo: false,
            showHousingBenefitInfo: false,
            showCommunicatingExpensesInfo: false,
            showSpecialMedicalExpensesInfo: false,
            showStudentLoanCostsInfo: false,
            showOtherChildSupportObligationsInfo: false,
            showVisitationExpensesInfo: false,
            showCommutingExpensesKmInfo: false
        }
    },
    methods: {
        toggleChildBenetRiseCalcFields() {
            this.showChildBenetRiseCalcFields = !this.showChildBenetRiseCalcFields;
        },
        toggleCommunicatingExpenseCalcFields() {
            this.showCommunicatingExpenseCalcFields = !this.showCommunicatingExpenseCalcFields;
        },
        toggleVisitationExpenseCalcFields() {
            this.showVisitationExpenseCalcFields = !this.showVisitationExpenseCalcFields;
        },
        toggleKMCalcFields() {
            this.showKMCalcFields = !this.showKMCalcFields;
        },
        toggleStudentLoanCalcFields() {
            this.showStudentLoanCalcFields = !this.showStudentLoanCalcFields;
        },
        update() {
            this.$api.put('guardian/' + this.guardian.code, this.guardian).then(response => {
                //console.log(response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
                //console.log(this.guardian.type)
            }).catch(error => {
                //console.log(error);
            });
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
}

.cell {
    flex: 1;
    padding: 5px;
}
</style>