<template>
    <HeaderLogo />
    <div class="row justify-content-xl-center page-terms">
        <div class="col-xl-12 content">

            <h1>Tutustu maksutta</h1>
            <p>Palvelut</p>
            <input disabled type="checkbox" v-model="order.services.elatuslaskuri"> Elatuslaskuri
            <br>
            <br>
            <div v-if="order.services.elatuslaskuri">
                <h2>KETKÄ VOIVAT TUTUSTUA PALVELUUN?</h2>
                Elatuslaskurin koekäyttötunnukset myönnetään vain lastenvalvojille, asianajo- tai lakiasiaintoimistoille
                sekä tuomioistuimille.
                <br>
                <br>
                <h2>PALVELUN SISÄLTÖ</h2>
                Elatuslaskuri seuraavilla ominaisuuksilla:
                <ul>
                    <li>Laskuriin syötettyjen tietojen tallennus, joka mahdollistaa laskennan jatkamisen myöhemmin (Huomioi, että koekäytössä syötetyt tiedot EIVÄT siirry maksulliseen tilaukseen)</li>
                    <li>Laskelman monistus</li>
                    <li>Tulojen/kulujen nimeäminen</li>
                    <li>Tulojen/kulujen automaattinen jaksotus (voit syöttää esimerkiksi 12 kuukauden tulon, jonka
                        laskuri jakaa yhdelle kuukaudelle)</li>
                    <li>Vuoroasumisen laskenta</li>
                    <li>Muun elatusvastuun automaattinen huomioiminen</li>
                    <li>Lähivanhemmuuden vaihto </li>
                    <!--<li>Lasten asuminen ristiin vanhempiensa luona</li>-->
                </ul>
                <br />
                <h2>HINTA </h2>
                Koekäyttö on <b>maksuton (0 €)</b>. Koekäytön jälkeen laskurin käyttö päättyy automaattisesti.
                <br />
                <br>
                <h2>YHTEYSTIEDOT</h2>
                Etunimi: <input v-model="customer.first_name" type="text"><br>
                Sukunimi: <input v-model="customer.last_name" type="text"><br>
                Tehtävänimike: <input v-model="customer.title" type="text"><br>
                Yritys/organisaatio: <input v-model="customer.organization" type="text"><br>
                Henkilökohtainen sähköpostiosoite: <input v-model="customer.email" type="text"><br>
                Suora matkapuhelinnumero: <input v-model="customer.mobile" type="text"><br>
                <br>
                <input type="checkbox" v-model="order.terms" /> Hyväksyn palvelun <a href="/#/terms"
                    target="_blank">käyttöehdot</a>.<br />
                <input type="checkbox" v-model="order.privacy"> Olen lukenut palvelun <a href="/#/privacy"
                    target="_blank">tietosuojaselosteen</a>. <br />
                <br>
                <o-button v-if="!working" :disabled="isDisabled" variant="primary" @click="getTrial">TILAA MAKSUTON KOEKÄYTTÖ</o-button>
                <o-button v-if="working" disabled variant="primary" @click="getTrial">TILAA MAKSUTON KOEKÄYTTÖ<i
                    class="fas fa-spinner fa-spin"></i></o-button>
                <div v-if="status == 'success'"><br><br><strong>Kiitos tilauksestasi! Tietojesi tarkistamisen jälkeen saat ilmoituksen tunnusten myöntämisestä tai hylkäämisestä.</strong></div>
                <div v-if="status == 'error'" style="color: red;"><br><br><strong>Koekäytön tilaus epäonnistui. Varmista että tiedot ovat oikein ja yritä uudelleen.</strong></div>
            </div>
            <br>
        </div>
       <FooterNav />
    </div>
</template>
<script>
import HeaderLogo from './HeaderLogo.vue';
import FooterNav from '@/components/FooterNav.vue'
export default {
    name: 'TrialForm',
    components: {
        HeaderLogo,
        FooterNav
    },
    computed: {
        isDisabled() {
            if(this.status == 'success') {
                return true;
            }
            if(this.customer.first_name == '') {
                return true;
            }
            if(this.customer.last_name == '') {
                return true;
            }
            if(this.customer.organization == '') {
                return true;
            }
            if(this.customer.email == '') {
                return true;
            }
            if(this.customer.mobile == '') {
                return true;
            }
            return !this.order.terms || !this.order.privacy;
        }
    },
    data() {
        return {
            working: false,
            status: 'none', // 'none', 'success', 'error
            customer: {
                first_name: '',
                last_name: '',
                title: '',
                organization: '',
                email: '',
                mobile: ''
            },
            order: {
                terms: false,
                privacy: false,
                services: {
                    elatuslaskuri: true
                }
            }
        }
    },
    methods: {
        getTrial() {
            this.working = true;
            this.$api.post('trial-request', this.customer).then(response => {
                this.working = false;
                //console.log(response.data);
                this.status = 'success';
            }).catch(error => {
                this.working = false;
                //console.log(error);
                this.status = 'error';
            });
        },
    }
}
</script>