import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import '@oruga-ui/theme-bulma/dist/bulma.css'
import router from './router'
import { useCalculationStore } from '@/stores/CalculationStore.js'
import App from './App.vue'
const pinia = createPinia()

let app = createApp(App);

let api = false;
let token = localStorage.getItem('token')
let key = localStorage.getItem('encryption_key')
if(!key) {
    key = "no-key"
}
if(token) {
    api = axios.create({ 
        baseURL: process.env.VUE_APP_API_HOST,
        headers: {
            'Authorization': 'Bearer ' + token,
            'X-Encryption-Key': key,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        } 
    })
}
else {  
    api = axios.create({
        baseURL: process.env.VUE_APP_API_HOST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }  
    })
}

app.config.globalProperties.$EUR = (number) => {
    if(number === null || number === undefined) return "0,00";
    const numberStr = number.toString();
    if(numberStr.lastIndexOf(',') > numberStr.lastIndexOf('.')) {
        let input = numberStr.replace(/\./g, '').replace(',', '.');
        let output = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(input));
        //console.log("EUR", input, output);
        return output;
    }
    else {
        let output = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(number));
        //console.log("EUR", number, output);  
        return output;
    }

};

app.config.globalProperties.$toPage = (name) => {
    router.push({ name: name });
};

app.config.globalProperties.$api = api;
// Global functions
app.config.globalProperties.T = function (key) {
    return useCalculationStore().translate(key);
};
app.config.globalProperties.capitalize = function (str) {
    if(!str) return '';
    str = str.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
};

app.use(Oruga, bulmaConfig)
app.use(router)
app.use(pinia)
app.mount('#app')
