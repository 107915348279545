<template>
    <HeaderLogo />
    <div class="row justify-content-xl-center page-terms">
        <div class="col-xl-12 content">
            <h1>Valmia Oy</h1>
            <p>Valmia Oy on juridiikan sovellusratkaisuihin erikoistunut innovatiivinen yritys.</p>
            <p>Olemme tuottaneet Elatuslaskuri.fi -palvelun vuodesta 2008 alkaen. Huhtikuusta 2024 Elatuslaskuri.fi on
                siirtynyt eLaskuri.fi -domainin alle.</p>
            <p>Onko Sinulla kysyttävää eLaskurin palveluista? Haluatko jättää palautetta?</p>
            <p>Ota yhteyttä!</p>
            <p>
                Valmia Oy (Y-tunnus 2215889-6)<br>
                Rantakatu 21<br>
                67100 Kokkola<br>
               <a href="mailto:asiakaspalvelu@elatuslaskuri.fi">asiakaspalvelu@elatuslaskuri.fi</a><br>
            </p>
        </div>
        <FooterNav />
    </div>
</template>
<script>
import HeaderLogo from '@/components/HeaderLogo.vue'
import FooterNav from '@/components/FooterNav.vue'
export default {
    name: 'ValmiaPage',
    components: {
        FooterNav,
        HeaderLogo
    },
    data() {
        return {
        }
    }
}
</script>