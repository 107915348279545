<template>
    <br>
    <div v-for="child in children" :key="child.code" class="breakdown-wrapper">
        <table v-if="!child.alternating_weeks" class="results-table">
            <thead>
                <tr>
                    <th>
                        <h2><i class="fa-sharp fa-solid fa-child"></i> {{ child.name }} ({{ child.age }}v) &nbsp; (Lähivanhempi: {{ child.living_with_guardian.name }})</h2>
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Yleiset kustannukset</td>
                    <td>{{ $EUR(child.cost_of_raising_a_child) }} €/kk</td>
                </tr>
                <tr v-if="child.housing_cost_part != '0,00'">
                    <td>Osuus asumismenoista </td>
                    <td v-if="child.AAAhousingCostAmount > 0">{{ $EUR(child.AAAhousingCostAmount) }} €/kk ({{ child.AAAhousingCostPercentage }}%)</td>
                    <td v-if="child.BBBhousingCostAmount > 0">{{ $EUR(child.BBBhousingCostAmount) }} €/kk ({{ child.BBBhousingCostPercentage }}%)</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'special_hobbies_costs') > 0">
                    <td>{{ T('special_hobbies_costs') }}
                        <div v-if="getTotal(child, 'children', 'special_hobbies_costs') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'special_hobbies_costs')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </td>
                    <td>{{ toEuro(getTotal(child, 'children', 'special_hobbies_costs')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'special_medical_expenses') > 0">
                    <td>{{ T('special_medical_expenses') }}
                        <div v-if="getTotal(child, 'children', 'special_medical_expenses') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'special_medical_expenses')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </td>
                    <td>{{ toEuro(getTotal(child, 'children', 'special_medical_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'daycare_expenses') > 0">
                    <td>{{ T('daycare_expenses') }}
                        <div v-if="getTotal(child, 'children', 'daycare_expenses') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'daycare_expenses')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </td>
                    <td>{{ toEuro(getTotal(child, 'children', 'daycare_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'other_special_costs') > 0">
                    <td>{{ T('other_special_costs') }}
                        <div v-if="getTotal(child, 'children', 'other_special_costs') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'other_special_costs')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </td>
                    <td>{{ toEuro(getTotal(child, 'children', 'other_special_costs')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'child_income') > 0">
                    <td>{{ T('child_income') }}
                        <div v-if="getTotal(child, 'children', 'child_income') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'child_income')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </td>
                    <td>{{ toEuro(getTotal(child, 'children', 'child_income')) }} €/kk</td>
                </tr>
                <tr>
                    <td>Vähennetään lapsilisä</td>
                    <td>{{ $EUR(child.child_benefit) }} €/kk</td>
                </tr>
                <tr>
                    <th>Elatuksen tarve</th>
                    <td>{{ $EUR(child.total) }} €/kk</td>
                </tr>
                <tr>
                    <td>Lähivanhempi</td>
                    <td v-if="child.custodial_guardian_id == close.id">{{ $EUR(child.close_guardian_share) }} €/kk ({{ child.close_guardian_percent }}% )</td>
                    <td v-if="child.custodial_guardian_id == remote.id">{{ $EUR(child.remote_guardian_share) }} €/kk ({{ child.remote_guardian_percent }}% )</td>
                </tr>
                <tr>
                    <td>Etävanhempi</td>
                    <td v-if="child.custodial_guardian_id != close.id">{{ $EUR(child.close_guardian_share) }} €/kk ({{ child.close_guardian_percent }}% )</td>
                    <td v-if="child.custodial_guardian_id != remote.id">{{ $EUR(child.remote_guardian_share) }} €/kk ({{ child.remote_guardian_percent }}% )</td>
                </tr>
                <tr v-if="child.remote_nights_per_month > 6">
                    <td>Luonapitovähennys</td>
                    <td>
                        {{ $EUR(child.custody_deduction) }} €/kk
                        <span v-if="child.remote_nights_per_month == 7"> (7-9 yötä/kk)</span>
                        <span v-if="child.remote_nights_per_month == 10"> (10-12 yötä/kk)</span>
                        <span v-if="child.remote_nights_per_month == 13"> (13-15 yötä/kk)</span>
                    </td>
                </tr>
                <tr>
                    <th>Elatusavun määrä</th>
                    <td>{{ $EUR(child.alimony) }} €/kk</td>
                </tr>
                <tr class="elatusapu-tr" v-if="child.alimony < income_assistance_per_child">
                    <td colspan="2">
                        <div class="elatusapu-info">Elatusapu <b>{{ $EUR(child.alimony) }}</b> on alhaisempi
                            kuin elatustuen määrä <b>{{ $EUR(income_assistance_per_child) }}</b>
                            <br><br>
                            Elatustukena maksettava määrä on siten <b>{{ $EUR(income_assistance_per_child - child.alimony) }}</b>
                            <br/>
                            Käyttäjä tekee kuitenkin lopullisen arvion elatustuen määrästä.
                        </div>
                    </td>
                </tr>
                <tr v-if="1 == 2">
                    <td colspan="2">
                        <pre> {{ child }}</pre>
                    </td>
                </tr>
            </tbody>
        </table>

        <table v-if="child.alternating_weeks" class="results-table">
            <thead>
                <tr>
                    <th>
                        <h2><i class="fa-sharp fa-solid fa-child"></i> {{ child.name }} ({{ child.age }}v) Vuoroasuminen, lapsilisän saaja: 
                            <span v-if="child.child_benefit_getter_guardian_id == close.id"> {{ close.name }}</span>
                            <span v-if="child.child_benefit_getter_guardian_id == remote.id"> {{ remote.name }}</span>
                        </h2>
                    </th>
                    <th>
                        <strong> {{ close.name }}</strong>
                    </th>
                    <th>
                        <strong>{{ remote.name }}</strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Yleiset kustannukset</th>
                    <td>{{ $EUR(child.close_guardian_basic_cost_share) }} €/kk ({{ child.close_guardian_basic_share }}%)</td>
                    <td>{{ $EUR(child.remote_guardian_basic_cost_share) }} €/kk ({{ child.remote_guardian_basic_share }}%)</td>
                </tr>
                <tr>
                    <th>Osuus asumismenoista </th>
                    <td>{{ $EUR(child.AAAhousingCostAmount) }} €/kk ({{ child.AAAhousingCostPercentage }}%)</td>
                    <td>{{ $EUR(child.BBBhousingCostAmount) }} €/kk ({{ child.BBBhousingCostPercentage }}%)</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'special_hobbies_costs') > 0">
                    <th>{{ T('special_hobbies_costs') }}
                        <div v-if="getTotal(child, 'children', 'special_hobbies_costs') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'special_hobbies_costs')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </th>
                    <td>{{ $EUR(getTotal(child, 'close', 'special_hobbies_costs')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(child, 'remote', 'special_hobbies_costs')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'special_medical_expenses') > 0">
                    <th>{{ T('special_medical_expenses') }}
                        <div v-if="getTotal(child, 'children', 'special_medical_expenses') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'special_medical_expenses')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </th>
                    <td>{{ $EUR(getTotal(child, 'close', 'special_medical_expenses')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(child, 'remote', 'special_medical_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'daycare_expenses') > 0">
                    <th>{{ T('daycare_expenses') }}
                        <div v-if="getTotal(child, 'children', 'daycare_expenses') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'daycare_expenses')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </th>
                    <td>{{ $EUR(getTotal(child, 'close', 'daycare_expenses')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(child, 'remote', 'daycare_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'other_special_costs') > 0">
                    <th>{{ T('other_special_costs') }}
                        <div v-if="getTotal(child, 'children', 'other_special_costs') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'other_special_costs')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </th>
                    <td>{{ $EUR(getTotal(child, 'close', 'other_special_costs')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(child, 'remote', 'other_special_costs')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(child, 'children', 'child_income') > 0">
                    <th>{{ T('child_income') }}
                        <div v-if="getTotal(child, 'children', 'child_income') > 0">
                            <small v-for="breakdown in getBreakdowns(child, 'child_income')" :key="breakdown.id">{{ breakdown.description }} ({{ $EUR(breakdown.amount) }} €/kk)<br></small>
                        </div>
                    </th>
                    <td>{{ $EUR(getTotal(child, 'children', 'child_income') / 2) }} €/kk</td>
                    <td>{{ $EUR(getTotal(child, 'children', 'child_income') / 2) }} €/kk</td>
                </tr>
                <tr v-if="1==2">
                    <th>DEBUG</th>
                    <td colspan="2">{{ child.child_benefit_getter_guardian_id }} | {{  close.id }} | {{  remote.id }}</td></tr>
                <tr>
                    <th>Vähennetään lapsilisä</th>
                    <td><span v-if="child.child_benefit_getter_guardian_id == 0 || child.child_benefit_getter_guardian_id == close.id">{{ $EUR(child.child_benefit) }} €/kk</span></td>
                    <td><span v-if="child.child_benefit_getter_guardian_id != 0 && child.child_benefit_getter_guardian_id == remote.id">{{ $EUR(child.child_benefit) }} €/kk</span></td>
                </tr>
                <tr>
                    <th><b>Yhteensä</b></th>
                    <td>{{ $EUR(child.close_guardian_total) }} €/kk</td>
                    <td>{{ $EUR(child.remote_guardian_total) }} €/kk</td>
                </tr>
                <tr>
                    <td>Elatuksen tarve</td>
                    <td colspan="2">
                         {{ $EUR(child.total) }} €/kk
                    </td>
                </tr>
                <tr>
                    <th>Jako elatuskyvyn mukaan</th>
                    <td>{{ $EUR(child.close_guardian_payment_share) }} €/kk ({{ child.close_guardian_percent }}%)</td>
                    <td>{{ $EUR(child.remote_guardian_payment_share) }} €/kk ({{ child.remote_guardian_percent }}%)</td>
                </tr>
                <tr>
                    <th><b>Elatusaputasaus ja saaja</b></th>
                    <td><span v-if="child.alimony_from == 'remote'">{{ $EUR(child.alimony) }} €/kk</span></td>
                    <td><span v-if="child.alimony_from == 'close'">{{ $EUR(child.alimony) }} €/kk</span></td>
                </tr>
                <tr>
                    <th>Maksaja</th>
                    <td>
                        <strong><span v-if="child.alimony_from == 'close'">{{ close.name }}</span></strong>
                    </td>
                    <td>
                        <strong><span v-if="child.alimony_from == 'remote'">{{ remote.name }}</span></strong>
                    </td>
                </tr>
                <tr class="elatusapu-tr" v-if="child.alimony < income_assistance_per_child">
                    <td colspan="3">
                        <div class="elatusapu-info">
                            <strong>Merkintä elatustukea varten</strong>
                            <br><br>
                            Elatustukea varten lasketussa vaihtoehtoisessa laskelmassa ei huomioda vuoroasuvan laspen/lasten asumiskustannuksia.<br>
                            <br>
                            <table style="background-color:#6fc4c5 !important; border-bottom: none !important;">
                                <tr>
                                    <td style="background-color:#6fc4c5 !important; width: 60%; border-bottom: none !important;">
                                        <br>
                                        Vaihtoehtoisen laskelman elatuskyvyt ovat:
                                    </td>
                                    <td style="background-color:#6fc4c5 !important; border-bottom: none !important;">{{ close.name }}<br> {{ $EUR(close.capacity_without_alts) }}</td>
                                    <td style="background-color:#6fc4c5 !important; border-bottom: none !important;">{{ remote.name }}<br>{{ $EUR(remote.capacity_without_alts) }}</td>
                                </tr>
                                <tr>
                                    <td style="background-color:#6fc4c5 !important; width: 60%; border-bottom: none !important;">
                                        <br>
                                        Vaihtoehtoinen elatuskyky alittaa elatustuen määrän {{ $EUR(income_assistance_per_child) }} €/kk
                                    </td>
                                    <td style="background-color:#6fc4c5 !important; border-bottom: none !important;"><span v-if="child.close_negative_alt_capacity">Kyllä</span><span v-if="!child.close_negative_alt_capacity">Ei</span></td>
                                    <td style="background-color:#6fc4c5 !important; border-bottom: none !important;"><span v-if="child.remote_negative_alt_capacity">Kyllä</span><span v-if="!child.remote_negative_alt_capacity">Ei</span></td>
                                </tr>
                            </table>
                            <br>Johtuuko elatustuen määrää pienempi elatusapu elatusvelvollisen puutteellisesta
                            elatuskyvystä:
                            <span v-if="child.alimony_from == 'remote'">
                                <span v-if="remote.capacity_without_alts <=0"><strong>Kyllä</strong></span>
                                <span v-if="remote.capacity_without_alts > 0 && remote.capacity_without_alts <= income_assistance_per_child"><strong>Merkintä on mahdollinen</strong></span>
                                <span v-if="remote.capacity_without_alts > income_assistance_per_child"><strong>Ei</strong></span> 
                            </span>
                            <span v-if="child.alimony_from == 'close'">
                                <span v-if="close.capacity_without_alts <=0"><strong>Kyllä</strong></span>
                                <span v-if="close.capacity_without_alts >0 && close.capacity_without_alts <= income_assistance_per_child"><strong>Merkintä on mahdollinen</strong></span>
                                <span v-if="close.capacity_without_alts > income_assistance_per_child"><strong>Ei</strong></span> 
                            </span>
                            <span><br><br>Elatustuen voi saada ainoastaan se vanhempi, jonka luona lapsi on kirjoilla.</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>
</template>
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
export default {
    name: 'GuardianResult',
    components: {
    },
    props: [
        'close',
        'remote',
        'income_assistance_per_child',
        'total_income_assistance'
    ],
    computed: {
        ...mapWritableState(useCalculationStore, { guardians: 'guardians', calculation: 'calculation', calculatedFields: 'calculatedFields', children: 'children' }),
    },
    methods: {
        toEuro(value) {
            //return value.toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
            return value.toLocaleString('de-DE', { minimumFractionDigits: 2 });
        },
        toFloat(stringValue) {
            let formattedValue = stringValue.replace('.', '').replace(',', '.');
            return parseFloat(formattedValue);

        },
        getBreakdowns(entity, field) {
            let breakdowns = []
            entity.breakdowns.forEach((breakdown) => {
                if (breakdown.field == field) {
                    breakdowns.push(breakdown);
                }
            });
            return breakdowns;
        },
        getTotal(entity, type, field) {
            if (this.calculatedFields?.[type]?.[entity.code]?.[field]) {
                return this.calculatedFields[type][entity.code][field];
            }
            return 0;
        },
    },
    data() {
        return {}
    }
}
</script>