<template>
    <div v-if="staticName && staticTotal && staticTotal > 0" class="total-breakdown">{{ $EUR(getTotal(entity, eType, field) + staticTotal) }} €/kk</div>
    <div v-if="!staticName || !staticTotal || staticTotal == 0" class="total-breakdown">{{ $EUR(getTotal(entity, eType, field)) }} €/kk</div>
    <div v-if="staticName == 'otherChildren' && staticData !== undefined && staticData.length > 0" style="padding-top:20px; padding-bottom:20px;">
        <table>
            <tr>
                <th style="padding: 0px; margin:0px; border: none;">Nimi</th>
                <th style="padding: 0px; margin:0px; border: none;">Ikä</th>
                <th style="padding: 0px; margin:0px; border: none;">Elatusvastuu</th>
            </tr>
            <tr v-for="otherChild in staticData" :key="otherChild.id">
                <td style="padding: 0px; margin:0px; border: none;">{{ otherChild.name }}</td>
                <td style="padding: 0px; margin:0px; border: none;">{{  otherChild.age }} <span v-if="otherChild.age != ''">v</span></td>
                <td style="padding: 0px; margin:0px; border: none;">{{ $EUR(otherChild.cost) }} €/kk</td>
            </tr>
        </table>
    </div>
    <div v-if="staticName == 'singleParentBenefit' && staticData !== undefined && staticData === 0" style="padding-top:10px; padding-bottom:10px;">
        <b>Lapsilisän yksinhuoltajakorotus: {{ $EUR(staticTotal) }} €</b>
    </div>
    <div class="breakdown-wrapper">
       <table class="add-new-breakdown" v-if="state[entityType] == entity.code && state.field == field">
            <tr>
                <th v-if="!isAlternatingWeeks" colspan="2" class="custom-width">Kuvaus</th>
                <th v-if="isAlternatingWeeks" colspan="1">Kuvaus</th>
                <th v-if="isAlternatingWeeks">Maksaja</th>
                <th>{{ transactionType }}</th>
                <th>Jakso</th>
              <th></th>
            </tr>
            <EditBreakdown :key="index" v-for="(breakdown, index) in getBreakdowns(entity, field)" :field="field" :fillData="breakdown" :entityType="entityType" :entity="entity" :isAlternatingWeeks="isAlternatingWeeks" :guardians="guardians"/>
            <tr>
                <td v-if="!isAlternatingWeeks" colspan="2" class="custom-width">
                    <o-input v-model="state.description" type="text" />
                </td>
                <td v-if="isAlternatingWeeks" colspan="1">
                    <o-input v-model="state.description" type="text" />
                </td>
                <td v-if="isAlternatingWeeks">
                    <o-select v-model="state.paying_guardian" style="width: 100%">
                        <option v-for="guardian in guardians" :key="guardian.code" :value="guardian.code">{{
                            guardian.name }}</option>
                    </o-select>
                </td>
                <td>
                    <o-input @blur="addBreakdown" v-model="state.amount" type="number" />
                </td>
                <td>
                    <o-select v-model="state.period">
                        <option value="0.5">2 vko</option>
                        <option value="1">1 kk</option>
                        <option value="2">2 kk</option>
                        <option value="3">3 kk</option>
                        <option value="4">4 kk</option>
                        <option value="5">5 kk</option>
                        <option value="6">6 kk</option>
                        <option value="7">7 kk</option>
                        <option value="8">8 kk</option>
                        <option value="9">9 kk</option>
                        <option value="10">10 kk</option>
                        <option value="11">11 kk</option>
                        <option value="12">12 kk</option>
                    </o-select>
                </td>
            </tr>
            <!--<tr class="add-new-breakdown-blur">
                <td>
                    <o-input v-model="state.description" type="text" />
                </td>
                <td>
                    <o-select v-model="state.paying_guardian" style="width: 100%">
                        <option v-for="guardian in guardians" :key="guardian.code" :value="guardian.code">{{
                            guardian.name }}</option>
                    </o-select>
                </td>
                <td>
                    <o-input v-model="state.amount" type="number" />
                </td>
                <td>
                    <o-select v-model="state.period">
                        <option value="0.5">2 vko</option>
                        <option value="1">1 kk</option>
                        <option value="2">2 kk</option>
                        <option value="3">3 kk</option>
                        <option value="4">4 kk</option>
                        <option value="5">5 kk</option>
                        <option value="6">6 kk</option>
                        <option value="7">7 kk</option>
                        <option value="8">8 kk</option>
                        <option value="9">9 kk</option>
                        <option value="10">10 kk</option>
                        <option value="11">11 kk</option>
                        <option value="12">12 kk</option>
                    </o-select>
                </td>
                <td>
                    <o-button @click="showAddForm" size="small"
                        variant="success" iconPack="fas" icon-left="plus">
                    </o-button>
                    <o-button v-if="state.id" :disabled="isSaveButtonDisabled" @click="updateBreakdown" size="small"
                        variant="success" iconPack="fas" icon-left="save">
                    </o-button>
                    <o-button @click="cancelAddBreakdown" size="small" variant="warning" iconPack="fas" icon-left="times">
                    </o-button>
                </td>
            </tr>-->
        </table>
        <!--<div class="add-new-breakdown-btn"  @click="showAddForm"><i
                class="fa-sharp fa-light fa-plus"></i> Lisää uusi rivi</div>-->
        <!--<div class="add-new-breakdown-btn-disabled" v-if="state.field != ''"><i class="fa-sharp fa-light fa-plus"></i> Lisää uusi</div>-->
    </div>
</template>
  
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
import EditBreakdown from "@/components/EditBreakdown";
export default {
    name: 'BreakDown',
    components: {
      EditBreakdown
    },
    created() {
        this.$store = useCalculationStore();
    },
    data() {
        return {
            editBreakdown: {},
            state: {
                id: '',
                amount: 0,
                description: '',
                period: 1,
                uses_alternating_weeks: false,
                paying_guardian: ''
            },
            editState: {}
        }
    },
    props: [
        'staticName',
        'staticData',
        'staticTotal',
        'isChildExpense',
        'isAlternatingWeeks',
        'guardians',
        'field',
        'entityType',
        'entity',
        'type',
        'preOpen'
    ],
    computed: {
        ...mapWritableState(useCalculationStore, { emptyState: 'newBreakdown', calculatedFields: 'calculatedFields' }),
        transactionType() {
            if (this.type == 'income') {
                return 'Tulo';
            }
            if (this.type == 'share') {
                return 'Osuus';
            }
            return 'Kulu';
        },
        eType() {
            if (this.entityType == 'guardian') {
                return 'guardians';
            }
            return 'children';
        },
        isSaveButtonDisabled() {
            if (this.state.amount > 0 && this.state.description != '') {
                if (this.isAlternatingWeeks) {
                    if (this.state.paying_guardian != '') {
                        return false;
                    }
                    return true;
                }
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.state = JSON.parse(JSON.stringify(this.emptyState));
        if (this.isAlternatingWeeks) {
            this.state.uses_alternating_weeks = true;
        }

        if (this.entity !== undefined && this.field !== undefined && this.entity.breakdowns !== undefined) {
            ////console.log('mounted',this.entity,this.field);
            let tmp = this.getBreakdowns(this.entity, this.field);
            ////console.log('tmp',tmp);
            if (tmp !== undefined && tmp.length == 0) {
               // if (this.preOpen) {

               // }
            }
          this.showAddForm();
        }

    },
    methods: {
        toEuro(value) {
            //return value.toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
            return value.toLocaleString('de-DE', { minimumFractionDigits: 2 });
        },
        toFloat(stringValue) {
            let formattedValue = stringValue.replace('.', '').replace(',', '.');
            return parseFloat(formattedValue);

        },
        getGuardian(code) {
            return this.guardians.find(guardian => guardian.code == code);
        },
        getGuardianName(code) {
            let guardian = this.getGuardian(code);
            if (guardian) {
                return guardian.name;
            }
            return '';
        },
        cancelAddBreakdown() {
            this.state[this.entityType] = '';
            this.state.field = '';
            this.state.period = 1;
            this.state.description = '';
            this.state.uses_alternating_weeks = false;
            this.state.paying_guardian = '';
        },
        getBreakdowns(entity, field) {
            let breakdowns = []
            if(entity.breakdowns) {
              entity.breakdowns.forEach((breakdown) => {
                if (breakdown.field == field) {
                  breakdowns.push(breakdown);
                }
              });
            }
            return breakdowns;
        },
        hasTotal(type, field) {
            if(type == "close") {
                if(this.close.totals && this.close.totals[field]) {
                    return this.close.totals[field];
                }
            } 
            else {
                if(this.remote.totals && this.remote.totals[field]) {
                    return this.remote.totals[field];
                }
            }
            return false;
        },
        getTotal(entity, type, field) {
            if(field == 'housing_costs') {
                //console.log('getting housing costs', entity, type, field, this.calculatedFields?.[type]?.[entity.code]?.[field]);
            }
            ////console.log('getTotal', entity, type, field, this.calculatedFields?.[type]?.[entity.code]?.[field])
            if (this.calculatedFields?.[type]?.[entity.code]?.[field]) {
                return this.calculatedFields[type][entity.code][field];
            }
            return 0;
        },
        showAddForm() {
            this.state[this.entityType] = this.entity.code;
            this.state.field = this.field;
        },
        showEditForm(breakdown) {
            //console.log('showEditForm', breakdown)
            this.state = breakdown;
            this.state[this.entityType] = this.entity.code;
            this.state.field = this.field;
        },
        addBreakdown() {
            //console.log("Adding breakdown", this.state);
            this.$api.post('breakdown', this.state).then((response) => {
                //console.log('Breakdown response', response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
                this.state = JSON.parse(JSON.stringify(this.emptyState));
              this.showAddForm();
            }).catch(error => {
                //console.log(error);
            });
        },
        deleteBreakdown(breakdown) {
            this.$api.delete('breakdown/' + breakdown.id).then(response => {
                //console.log(response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
            }).catch(error => {
                //console.log(error);
            });
        },
    }
}
</script>