import { defineStore } from 'pinia'
export const useCalculationStore = defineStore('calculation', {
    state: () => ({ 
        lang: 'fin',
        guardians: [],
        translations: [],
        settings: [],
        calculatedFields: [], 
        calculation: {
            code: '',
            name: '',
            ahvenanmaa: false,
            children: [
                {
                    close_guardian_basic_share: 50,
                    remote_guardian_basic_share: 50,
                    name: '',
                    code: '',
                    age: 0,
                    breakdowns: []
                }
            ],
            guardians: [
                {
                    name: '',
                    code: '',
                    type: 'close'
                },
                {
                    name: '',
                    code: '',
                    type: 'remote'
                },
            ] 
        },
        children: [],
        newBreakdown: {
            paying_guardian: '',
            uses_alternating_weeks: false,
            children: '',
            guardian: '',
            field: '',
            description: '',
            amount: 0,
            period: 1
        },
        token: '',
    }),
    getters: {
      state: (state) => state,
    },
    actions: {
        translate(string) {
            const translation = this.translations.find(translation => translation.name === string)
            return translation ? translation[this.lang] : string
        }
    },
  })