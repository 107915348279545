<template>
    <RouterLink to='/'><img class="logo" src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg">
    </RouterLink><br />
    <div class="row justify-content-xl-center page-terms">
        <div v-if="!success && !error" class="col-xl-12 content">
            <h1>Uuden salasanan asettaminen</h1>
            <o-field label="Salasana">
                <o-input icon-pack="fas" type="password" v-model="password" password-reveal />
            </o-field>
            <span v-if="!isStrongPassword">Salasanan tulee olla vähintään 8 merkkiä pitkä<br>sekä siinä tulee olla
                numeroita sekä ISOJA ja pieniä kirjaimia.<br><br></span>
            <o-field label="Salasana uudelleen">
                <o-input icon-pack="fas" type="password" v-model="password_confirmation" password-reveal />
            </o-field>
            <span v-if="isStrongPassword && password != password_confirmation">Salasanat eivät täsmää.<br><br></span>

            <o-button v-if="!working" :disabled="isDisabled" variant="primary" @click="setPassword">Vaihda
                salasana</o-button>
            <o-button v-if="working" disabled variant="primary" @click="setPassword">Vaihda salasana<i
                    class="fas fa-spinner fa-spin"></i></o-button>
        </div>
        <div v-if="success" class="col-xl-12 content">
            <h1>Salasana vaihdettu!</h1>
            <p>Voit nyt kirjautua sisään uudella salasanallasi.</p>
            <o-button variant="primary" @click="$router.push('/')">Siirry etusivulle</o-button>
        </div>
        <div v-if="error" class="col-xl-12 content">
            <h1>Salasanan vaihto epäonnistui!</h1>
            <p>Salasanan vaihtolinkki ehti vanhentua tai se on jo käytetty.</p>
            <o-button variant="primary" @click="$router.push('/')">Siirry etusivulle</o-button>
        </div>
    <FooterNav />
    </div>
</template>
<script>
import FooterNav from '@/components/FooterNav.vue'
export default {
    components: {
        FooterNav
    },
    name: 'NewPassword',
    data() {
        return {
            error: false,
            working: false,
            success: false,
            email: '',
            token: '',
            password: '',
            password_confirmation: '',
        }
    },
    mounted() {
        this.token = this.$route.params.token;
        this.email = this.$route.params.email;
    },
    computed: {
        isDisabled() {
            return !this.password || !this.password_confirmation || !this.isStrongPassword || this.password !== this.password_confirmation;
        },
        isStrongPassword() {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return regex.test(this.password);
        }
    },
    methods: {
        setPassword() {
            this.working = true;
            this.$api.post('password-reset/' + this.token, {
                token: this.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }).then(response => {
                //console.log(response.data);
                this.working = false;
                this.success = true;
            }).catch(error => {
                this.working = false;
                this.error = true;
                //console.log("Password reset failed")
                //console.log(error, error.response, error.response.data, error.response.data.errors, error.response.data.errors.state);
            });
        }
    }
}
</script>