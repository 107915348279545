<template>
    <tr v-for="otherChild in otherChildren" :key="'oc-' + otherChild.id">
        <td>
            <table>
                <tr>
                    <td style="text-align: left !important;">
                        Nimi<br>
                        <o-input class="input" v-model="otherChild.name" @blur="update(otherChild)" />
                    </td>
                    <td style="text-align: left !important;">
                        Ikä<br>
                        <o-select v-model="otherChild.age" @change="update(otherChild)">
                            <option value="0">0v</option>
                            <option value="1">1v</option>
                            <option value="2">2v</option>
                            <option value="3">3v</option>
                            <option value="4">4v</option>
                            <option value="5">5v</option>
                            <option value="6">6v</option>
                            <option value="7">7v</option>
                            <option value="8">8v</option>
                            <option value="9">9v</option>
                            <option value="10">10v</option>
                            <option value="11">11v</option>
                            <option value="12">12v</option>
                            <option value="13">13v</option>
                            <option value="14">14v</option>
                            <option value="15">15v</option>
                            <option value="16">16v</option>
                            <option value="17">17</option>
                            <option value="18">18+</option>
                        </o-select>
                    </td>
                    <td v-if="houseMate" style="text-align: left !important;">
                        Onko asuinkumppani lapsen vanhempi?<br>
                        <o-select v-model="otherChild.housemate_is_parent" @change="update(otherChild)">
                            <option value="0">Ei</option>
                            <option value="1">Kyllä</option>
                            <option value="2">Kyllä, mutta hänellä ei ole elatuskykyää</option>
                        </o-select>
                    </td>
                    <td v-if="!houseMate"></td>
                    <td></td>
                </tr>
                <tr v-if="otherChild.housemate_is_parent > 0 && otherChild.age < 18">
                    <td></td>
                    <td></td>
                    <td><o-button variant="danger"
                            @click="deleteChild(otherChild)">
                            <i class="fa-sharp fa-solid fa-trash-xmark"></i> Poista
                        </o-button></td>
                    <td></td>
                </tr>
                <tr v-if="otherChild.housemate_is_parent == 0">
                    <td style="text-align: left !important;" v-if="otherChild.age < 18">
                        Asuminen:<br>
                        <o-select v-model="otherChild.alternating_weeks" @change="update(otherChild)">
                            <option value="0">Asuu vanhemmalla</option>
                            <option value="1">Vuoroasuminen</option>
                        </o-select>
                    </td>
                    <td style="text-align: left !important;" v-if="otherChild.age < 18 && otherChild.alternating_weeks">
                        Lasketaanko mukaan asumiskustannusten jakoon?<br>
                        <o-select v-model="otherChild.calculate_housing_cost_share" @change="update(otherChild)">
                            <option value="0">Ei</option>
                            <option value="1">Kyllä</option>
                        </o-select>
                    </td>
                    <td style="text-align: left !important;" v-if="otherChild.alternating_weeks == 0 && otherChild.age < 18">
                        Elatusapu/tuki:<br>
                        <input type="number" v-model="otherChild.alimony" @change="update(otherChild)" />
                    </td>
                    <td style="text-align: left !important;" v-if="otherChild.alternating_weeks == 1 && otherChild.age < 17">
                        Lapsilisä:<br>
                        <o-select v-model="otherChild.guardian_gets_child_benefit" @change="update(otherChild)">
                            <option value="0">Toiseen talouteen</option>
                            <option value="1">Vanhemmalle</option>
                        </o-select>
                    </td>
                    <td><o-button v-if="otherChild.alternating_weeks == 0 || otherChild.age > 17" variant="danger"
                            @click="deleteChild(otherChild)">
                            <i class="fa-sharp fa-solid fa-trash-xmark"></i> Poista
                        </o-button></td>
                        <td v-if="otherChild.age > 17"></td>

                </tr>
                <tr v-if="otherChild.housemate_is_parent == 0 && otherChild.alternating_weeks == 1 && otherChild.age < 18">
                    <td style="text-align: left !important;" v-if="otherChild.alternating_weeks == 1">
                        Elatusapu/tuki:<br>
                        <o-select v-model="otherChild.alimony_type" @change="update(otherChild)">
                            <option value="default">Ei saa eikä maksa</option>
                            <option value="in">Saa elatusapua/tukea</option>
                            <option value="out">Maksaa elatusapua</option>
                        </o-select>
                    </td>
                    <td v-if="otherChild.alimony_type != 'default'" style="text-align: left !important;">
                        Määrä:<br>
                        <input type="number" v-model="otherChild.alimony" @change="update(otherChild)" />
                    </td>
                    <td><o-button v-if="otherChild.alternating_weeks == 1" variant="danger"
                            @click="deleteChild(otherChild)">
                            <i class="fa-sharp fa-solid fa-trash-xmark"></i> Poista
                        </o-button></td>
                    <td v-if="otherChild.alimony_type == 'default'"></td>
                </tr>
                <tr><td colspan="3"><hr></td><td></td></tr>
            </table>

        </td>
    </tr>
    <tr>
        <td>
            <table>
                <tr>
                    <td style="text-align: left !important;">
                        Nimi<br>
                        <o-input class="input" v-model="state.name" />
                    </td>
                    <td style="text-align: left !important;">
                        Ikä<br>
                        <o-select v-model="state.age">
                            <option value="0">0v</option>
                            <option value="1">1v</option>
                            <option value="2">2v</option>
                            <option value="3">3v</option>
                            <option value="4">4v</option>
                            <option value="5">5v</option>
                            <option value="6">6v</option>
                            <option value="7">7v</option>
                            <option value="8">8v</option>
                            <option value="9">9v</option>
                            <option value="10">10v</option>
                            <option value="11">11v</option>
                            <option value="12">12v</option>
                            <option value="13">13v</option>
                            <option value="14">14v</option>
                            <option value="15">15v</option>
                            <option value="16">16v</option>
                            <option value="17">17v</option>
                            <option value="18">18+</option>
                        </o-select>
                    </td>
                    <td v-if="houseMate" style="text-align: left !important;">
                        Onko asuinkumppani lapsen vanhempi?<br>
                        <o-select v-model="state.housemate_is_parent">
                            <option value="0">Ei</option>
                            <option value="1">Kyllä</option>
                            <option value="2">Kyllä, mutta hänellä ei ole elatuskykyää</option>
                        </o-select>
                    </td>
                    <td v-if="!houseMate"></td>
                    <td></td>
                </tr>
                <tr v-if="state.housemate_is_parent > 0">
                    <td></td>
                    <td></td>
                    <td><o-button variant="primary" @click="save">Tallenna</o-button></td>
                    <td></td>
                </tr>
                <tr v-if="state.housemate_is_parent == 0">
                    <td style="text-align: left !important;" v-if="state.age < 18">
                        Asuminen:<br>
                        <o-select v-model="state.alternating_weeks">
                            <option value="0">Asuu vanhemmalla</option>
                            <option value="1">Vuoroasuminen</option>
                        </o-select>
                    </td>
                    <td style="text-align: left !important;" v-if="state.alternating_weeks == 1 && state.age < 18">
                        Lasketaanko mukaan asumiskustannusten jakoon?<br>
                        <o-select v-model="state.calculate_housing_cost_share">
                            <option value="0">Ei</option>
                            <option value="1">Kyllä</option>
                        </o-select>
                    </td>
                    <td style="text-align: left !important;" v-if="state.alternating_weeks == 0 && state.age < 18">
                        Elatusapu/tuki:<br>
                        <input type="number" v-model="state.alimony" />
                    </td>
                    <td  v-if="state.age > 17"></td>
                    <td style="text-align: left !important;" v-if="state.alternating_weeks == 1 && state.age < 17">
                        Lapsilisä:<br>
                        <o-select v-model="state.guardian_gets_child_benefit">
                            <option value="0">Toiseen talouteen</option>
                            <option value="1">Vanhemmalle</option>
                        </o-select>
                    </td>
                    <td><o-button v-if="state.alternating_weeks == 0 || state.age > 17" variant="primary"
                            @click="save">Tallenna</o-button></td>

                </tr>
                <tr v-if="state.housemate_is_parent == 0 && state.alternating_weeks == 1 && state.age < 18">
                    <td style="text-align: left !important;" v-if="state.alternating_weeks == 1">
                        Elatusapu/tuki:<br>
                        <o-select v-model="state.alimony_type">
                            <option value="default">Ei saa eikä maksa</option>
                            <option value="in">Saa elatusapua/tukea</option>
                            <option value="out">Maksaa elatusapua</option>
                        </o-select>
                    </td>
                    <td v-if="state.alimony_type != 'default'" style="text-align: left !important;">
                        Määrä:<br>
                        <input type="number" v-model="state.alimony" />
                    </td>
                    <td><o-button v-if="state.alternating_weeks == 1" variant="primary"
                            @click="save">Tallenna</o-button></td>
                    <td v-if="state.alimony_type == 'default'"></td>
                </tr>
            </table>
        </td>
    </tr>
</template>

<script>

export default {
    name: 'OtherChild',
    data() {
        return {
            state: {
                id: '',
                calculate_housing_cost_share: 0,
                alternating_weeks: 0,
                guardian_gets_child_benefit: 0,
                name: '',
                age: '',
                housemate_is_parent: 0,
                alimony_type: 'default',
                alimony: 0,
            },
        }
    },
    props: [
        'houseMate',
        'guardianCode',
        'otherChildren'
    ],
    methods: {
        save() {
            if (this.state.name != '' && this.state.age != '') {
                if (!this.houseMate) {
                    this.state.housemate_is_parent = 0;
                }
                if(this.state.alimony_type == 'default' || this.state.age > 17) {
                    this.state.alimony = 0;
                }
                //console.log('Saving', this.state);
                this.$api.post('other-child/' + this.guardianCode, this.state).then((response) => {
                    this.state = {
                        id: '',
                        calculate_housing_cost_share: 0,
                        alternating_weeks: 0,
                        guardian_gets_child_benefit: 0,
                        name: '',
                        age: '',
                        housemate_is_parent: 0,
                        alimony_type: 'default',
                        alimony: 0,
                    }
                    //console.log('Other child response', response.data);
                    let emitter = require('tiny-emitter/instance');
                    emitter.emit('update-calculation');
                }).catch(error => {
                    //console.log(error);
                });
            }
        },
        update(otherChild) {
            if (!this.houseMate) {
                otherChild.housemate_is_parent = 0;
            }
            if(otherChild.alimony_type == 'default' || otherChild.age > 17) {
                    otherChild.alimony = 0;
                }
            //console.log('Updating', otherChild);
            this.$api.put('other-child/' + this.guardianCode + '/' + otherChild.id, otherChild).then((response) => {
                //console.log('Other child response', response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
            }).catch(error => {
                //console.log(error);
            });
        },
        deleteChild(otherChild) {
            //console.log('Deleting', otherChild);
            this.$api.delete('other-child/' + this.guardianCode + '/' + otherChild.id).then((response) => {
                //console.log('Other child response', response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
            }).catch(error => {
                //console.log(error);
            });
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
}

.cell {
    padding: 15px;
    margin: 10px;
    width: 25%;
    text-align: left;
    flex: 1;
    padding: 0px;
    width: auto;
}

.half {
    width: 50%;
}
</style>