<template>
    <HeaderLogo />
    <div class="row justify-content-xl-center page-terms">
        <div class="col-xl-12 content">
            <h1>Käyttöehdot</h1>
            <br />
            <h2>EHTOJEN SITOVUUS</h2>
            <ol style="counter-reset: custom-number 0;">
                <li>Tämä käyttöehtosopimus muodostuu sitovasti Valmia Oy:n (jälj. Palveluntarjoaja) omistaman ja ylläpitämän eLaskuri.fii nettipalvelun (jälj. Palvelu) sekä palvelun tilaajan tai tilaajan edustaman yrityksen tai organisaation (myöh. asiakas) välille. Sopimus astuu voimaan asiakkaan hyväksyessä Palvelun tilauksen joko suullisesti, sähköisesti tai kirjallisesti. Samalla asiakas hyväksyy tämän käyttöehtosopimuksen ja sitoutuu noudattamaan sitä.</li>
                <li>Palvelun avulla asiakas voi laskea lapsen elatusavun. Elatusapu voidaan laskea yhtäaikaisesti yhdestä seitsemään lapselle. Palvelu ei sovellu aviopuolison elatusavun tai lapsen koulutusavustuksen laskemiseen. <strong>Palvelun hintaan ei sisälly palvelun teknistä tai asiasisällöllistä (lainopillista) käyttötukea.</strong></li>
                <li><strong>Asiakkaan tekemät tilaukset ovat sitovia</strong> ja asiakas on velvollinen maksamaan tilaamansa palvelun. Tilatut palvelut laskutetaan yleensä vuoden ajalle (12 kk) kerrallaan, ellei toisin ole ilmoitettu tai asiakkaan kanssa sovittu. Maksuehto 10 päivää tilauksesta. Tilauksissa noudatetaan kunakin ajanhetkenä voimassa olevaa hinnastoa.</li>
                <li><strong>Asiakkaalle toimitetaan tilauksen vastaanottamisen ja vahvistamisen jälkeen Palvelun käyttöön ja hallintaan oikeuttava käyttäjätunnus ja salasana.</strong> Käyttöoikeus on käyttäjäkohtainen tai organisaatiolisensseissä tilauksessa määritellyn käyttöoikeusmäärän mukainen. Käyttöoikeutta ei saa luovuttaa kolmannellle ilman Palveluntarjoajan nimenomaista suostumusta. Kiellettyä on esimerkiksi käyttäjätunnuksen luovuttaminen lastenvalvojalta elatusvelvolliselle tai elatusapua saavalle. Asiakas sitoutuu pitämään käyttäjätunnuksesta ja salasanasta hyvää huolta ja ilmoittamaan Palveluntarjoajalle välittömästi, mikäli käyttäjätunnus tai salasana on tullut epähuomioissa sivullisen tietoon.</li>
            </ol>
            <h2>TILAUSJAKSO JA PALVELUN LOPETTAMINEN</h2>
            <ol>
                <li><strong>Tilausjakso on 12 kuukautta. Tilaus on kestotilaus.</strong> Tilaus jatkuu automaattisesti seuraavat 12 kuukautta, jollei tilaaja irtisano sopimusta kahta viikkoa ennen uuden sopimuskauden alkua. Maksettuja maksuja ei palauteteta.</li>
            </ol>
            <h2>LASKUTUS</h2>
            <ol>
                <li><storng>Palvelusta toimitetaan vuosittain lasku.</storng> Maksuaika 14 päivää netto. Myöhästyneistä laskuista voidaan periä maksumuistutusmaksu ja viivästyskorko myöhästymisen ajalta. Palveluntarjoajalla on oikeus sulkea Asiakkaan palvelu maksamattomien laskujen johdosta. Asiakas sitoutuu pitämään Palvelutarjoajan tiedossa ajantasaiset laskutus-, yhteys- ja profiilitietonsa.</li>
            </ol>
            <h2>MUUT PALVELUN KÄYTTÖEHDOT</h2>
            <ol>
                <li><storng>Asiakas ei saa jälleenmyydä</storng> tai muutoinkaan luovuttaa palvelua kolmannelle osapuolelle, ellei toisin ole sovittu.</li>
            </ol>
            <h2>PALVELUNTARJOAJAN OIKEUDET JA VASTUU</h2>
            <ol>
                <li><strong>Palveluntarjoaja pyrkii tarjoamaan asiakkaalle mahdollisimman korkealaatuista ja katkoksetonta teknistä Palvelua. Asiakas kuitenkin tiedostaa, että laadukkaidenkin tietoverkkopalvelinkokonaisuuksien toiminnassa saattaa olla hetkellisiä toiminta- tai huoltokatkoksia</strong> tai toiminta voi erilaisissa vika-, kriisi- tai force majeure -tilanteissa jopa keskeytyä tietyksi aikaa. Asiakas myös tiedostaa, että osa yhteyskatkoksista voi johtua myös kolmannen osapuolen tarjoaman yhteyden tilapäisistä katkoksista tai kolmannen osapuolen aiheuttamista verkkohyökkäyksistä, joihin palveluntarjoaja ei voi vaikuttaa. Palveluntarjoaja pyrkii kuitenkin rajoittamaan erilaiset suunnitellut huoltokatkokset minimiin, sijoittamaan ne ensisijaisesti yöaikaan ja informoimaan niistä mahdollisuuksien mukaan etukäteen verkkosivuilla. Lyhyemmät käyttö- tai huoltokatkokset eivät aiheuta Palveluntarjoajalle korvausvelvollisuutta tai Asiakkaalle oikeutta irtisanoa palvelua ilman irtisanomisaikaa, vaan asiakas hyväksyy niiden mahdollisen olemassaolon välttämättömänä osana palvelun toimintaa. Palveluntarjoajan korvausvelvollisuus asiakkaalle päätetään tapauskohtaisesti, mutta pääsääntöisesti se rajoittuu ainoastaan Palveluntarjoajan omasta toiminnasta johtuviin pidempiin toiminnan katkoksiin. Tällöinkin korvausvelvollisuus rajoittuu korkeintaan käyttöajan lisäämiseen ja asiakkaan oikeuteen irtisanoa palvelu ilman irtisanomisaikaa. Palveluntarjoaja ei vastaa millään tavalla sellaisista vahingoista, jotka johtuvat asiakkaasta, asiakkaan omista toimista tai kolmannen osapuolen toimista tai toiminnan katkoksista.</li>
                <li><strong>Palveluntarjoajalla on oikeus teknisistä sekä tietoturvasyistä kirjata asiakastunnukselle verkko-osoitekohtaisesti palvelimen käytöstä erilaisia lokitiedostoja</strong>, pääasiallisesti käyttö- ja virhelokia. Lokitiedostoja voidaan käyttää mahdollisten virhetilanteiden, väärinkäytösten ja tietoturvamurtojen selvittämiseen.</li>
                <li><strong>Palveluntarjoajalla on oikeus purkaa tämä palvelusopimus perustellusti ilman irtisanomisaikaa, mikäli asiakas ei noudata näitä käyttöehtoja</strong> tai käyttöehdot eivät täyty asiakkaan osalta tilaushetkellä tai sopimuksen voimaan astumisen jälkeen. Palveluntarjoajalla on tällöin myös oikeus sulkea tilattu palvelu.</li>
                <li><strong>Palveluntarjoaja pyrkii siihen, että Palvelun laskentaominaisuudet ja asiasisältö vastaavat kulloinkin voimassaolevia säädöksiä ja viranomaisten ohjeita. Asiakas kuitenkin tiedostaa, että laadukkaaseenkin palveluun saattaa sisältyä laskenta, asia- tai kirjoitusvirheitä.</strong> Palveluntarjoaja ei vastaa millään tavalla sellaisista vahingoista, jotka johtuvat siitä, että Palvelun avulla laskettu laskelma ei olisikaan laskentahetken mukaisten säännösten tai määräysten mukainen.</li>
            </ol>
            <h2>OIKEUS KÄYTTÖEHTOMUUTOKSIIN</h2>
            <ol>
                <li><strong>Palveluntarjoajalla on oikeus muuttaa näitä käyttöehtoja</strong> tai muuttaa järjestelmän ominaisuuksia tai hinnoittelua parhaaksi katsomallaan tavalla ensimmäisen tilausjakson jälkeen ja niin päättäessään soveltaa uutta hinnastoa myös vanhoihin asiakkaisiin. Mahdollisista asiakasta koskevista muutoksista pyritään kuitenkin ilmoittamaan mahdollisuuksien mukaan vähintään sähköpostitse. Käyttöehtojen mahdolliset muutokset eivät kuitenkaan aiheuta käyttöehtosopimuksen raukeamista palveluntarjoajan ja asiakkaan välillä.</li>
            </ol>
            <h2>OIKEUDET AINEISTOON</h2>
            <ol>
                <li><strong>Palveluntarjoajan tuottaman aineiston omistus- ja kaikki muut oikeudet (käsittäen tekijänoikeudet ja muut immateriaalioikeudet) kuuluvat Palveluntarjoajalle.</strong></li>
            </ol>
            <h2>ERIMIELISYYDET</h2>
            <ol>
                <li><strong>Tätä sopimusta koskevat erimielisyydet</strong> pyritään ratkaisemaan ensisijaisesti Asiakkaan ja Palveluntarjoajan välisin neuvotteluin. Tuomioistuinkäsittely tapahtuu Pohjanmaan käräjäoikeudessa.</li>
            </ol>
        </div>
        <FooterNav />
    </div>
</template>
<script>
import FooterNav from './FooterNav.vue';
import HeaderLogo from './HeaderLogo.vue';
export default {
    name: 'TermsPage',
    components: {
        FooterNav,
        HeaderLogo
    },
}
</script>
<style scoped>
li {
  list-style-type: none;
}
li::before {
  counter-increment: custom-number;
  content: counter(custom-number) ". ";
}
</style>