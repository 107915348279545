<template>
    <table class="calc-table">
        <tr>
            <td><span><strong>{{ capitalize(T('name')) }}</strong></span></td>
            <td>
                <o-input class="input" v-model="child.name" @blur="update" />
            </td>
        </tr>
        <tr>
            <td><span><strong>{{ capitalize(T('age')) }}</strong></span></td>
            <td>
                <o-select v-model="child.age" @change="update">
                    <option value="0">0v</option>
                    <option value="1">1v</option>
                    <option value="2">2v</option>
                    <option value="3">3v</option>
                    <option value="4">4v</option>
                    <option value="5">5v</option>
                    <option value="6">6v</option>
                    <option value="7">7v</option>
                    <option value="8">8v</option>
                    <option value="9">9v</option>
                    <option value="10">10v</option>
                    <option value="11">11v</option>
                    <option value="12">12v</option>
                    <option value="13">13v</option>
                    <option value="14">14v</option>
                    <option value="15">15v</option>
                    <option value="16">16v</option>
                    <option value="17">17v</option>
                </o-select>
            </td>
        </tr>

        <tr class="valign-top">
            <td colspan="2">
                <div class="container">
                    <div class="cell">
                        <strong>{{ capitalize(T('living')) }}</strong>
                        <br/><span @click="showLivingInfo = !showLivingInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showLivingInfo">Näytä ohje</small><small v-if="showLivingInfo">Piilota ohje</small></span>
                    </div>
                    <div class="cell">
                        <input type="checkbox" v-model="custodialGuardianIsCloseGuardianBoolean" @change="update"
                            id="closeGuardianName2" /><label for="closeGuardianName2">{{ closeGuardianName
                            }}</label><br>
                        <input type="checkbox" v-model="custodialGuardianIsRemoteGuardianBoolean" @change="update"
                            id="remoteGuardianName2" /><label for="remoteGuardianName2">{{ remoteGuardianName
                            }}</label><br>
                        <input type="checkbox" v-model="alternatingWeeksBoolean" @change="update"
                            id="alternatingweeks" /><label for="alternatingweeks">{{ capitalize(T('alternating_weeks'))
                            }}</label>
                        <div v-if="alternatingWeeksBoolean" class="is-flex is-justify-content-center"
                            style="margin-top: 20px">
                            <table class="inner-table">
                                <tr>
                                    <td colspan="2"><strong>Yleisten kustannusten jako</strong></td>
                                </tr>
                                <tr>
                                    <td>{{ closeGuardianName }}
                                        <o-select v-model="child.close_guardian_basic_share"
                                            @change="updateRemoteGuardianShare">
                                            <option value="0">0%</option>
                                            <option value="10">10%</option>
                                            <option value="20">20%</option>
                                            <option value="30">30%</option>
                                            <option value="40">40%</option>
                                            <option value="50">50%</option>
                                            <option value="60">60%</option>
                                            <option value="70">70%</option>
                                            <option value="80">80%</option>
                                            <option value="90">90%</option>
                                            <option value="100">100%</option>
                                        </o-select>
                                    </td>
                                    <td>{{ remoteGuardianName }}
                                        <o-select v-model="child.remote_guardian_basic_share"
                                            @change="updateCloseGuardianShare">
                                            <option value="0">0%</option>
                                            <option value="10">10%</option>
                                            <option value="20">20%</option>
                                            <option value="30">30%</option>
                                            <option value="40">40%</option>
                                            <option value="50">50%</option>
                                            <option value="60">60%</option>
                                            <option value="70">70%</option>
                                            <option value="80">80%</option>
                                            <option value="90">90%</option>
                                            <option value="100">100%</option>
                                        </o-select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="showLivingInfo" v-html="T('info_alternating_weeks')" class="info-paragraph"></div>
                </transition>
            </td>
        </tr>
        <tr class="valign-top" v-if="alternatingWeeksBoolean">
            <td>
                <strong>Lapsilisän saaja</strong>
            </td>
            <td>
                <input type="checkbox" v-model="childBenefitToCloseGuardianBoolean" @change="update"
                    id="closeGuardianName" /><label for="closeGuardianName">{{ closeGuardianName }}</label><br>
                <input type="checkbox" v-model="childBenefitToRemoteGuardianBoolean" @change="update"
                    id="remoteGuardianName" /><label for="remoteGuardianName">{{ remoteGuardianName }} </label>
            </td>
        </tr>
        <tr class="valign-top" v-if="!child.alternating_weeks">
            <td colspan="2">
                <div class="container">
                    <div class="cell"> 
                        <strong>{{ capitalize(T('remote_nights_per_month')) }}</strong>
                        <br/><span @click="showRemoteNightsInfo = !showRemoteNightsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showRemoteNightsInfo">Näytä ohje</small><small v-if="showRemoteNightsInfo">Piilota ohje</small></span>
                     </div>
                    <div class="cell"> <o-select @change="update" v-model="child.remote_nights_per_month"
                            style="width: 100px; margin: auto">
                            <option value="0">0-6</option>
                            <option value="7">7-9</option>
                            <option value="10">10-12</option>
                            <option value="13">13-15</option>
                        </o-select></div>
                </div>

                <transition name="fade">
                    <div v-if="showRemoteNightsInfo" v-html="T('info_remote_nights_per_month')" class="info-paragraph">
                    </div>
                </transition>
            </td>
        </tr>
        <tr>
            <td class="breakdown-td" colspan="2">
                <strong>{{ capitalize(T('special_hobbies_costs')) }}</strong>
                <br/><span @click="showSpecialHobbiesCostsInfo = !showSpecialHobbiesCostsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showSpecialHobbiesCostsInfo">Näytä ohje</small><small v-if="showSpecialHobbiesCostsInfo">Piilota ohje</small></span>

                <transition name="fade">
                    <div v-if="showSpecialHobbiesCostsInfo" v-html="T('info_special_hobbies_costs')"
                        class="info-paragraph">
                    </div>
                </transition>
                <BreakDown :pre-open="true" :guardians="calculation.guardians"
                    :is-alternating-weeks="alternatingWeeksBoolean" :is-child-expense="true" :entity="child" type="cost"
                    entity-type="children" field="special_hobbies_costs" />
            </td>
        </tr>
        <tr>
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showSpecialMedicalExpensesFields = !showSpecialMedicalExpensesFields">{{ capitalize(T('special_medical_expenses')) }} &nbsp;
                <i v-if="!showSpecialMedicalExpensesFields" class="fa-solid fa-circle-plus"></i><i v-if="showSpecialMedicalExpensesFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showSpecialMedicalExpensesFields" @click="showSpecialMedicalExpensesInfo = !showSpecialMedicalExpensesInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showSpecialMedicalExpensesInfo">Näytä ohje</small><small v-if="showSpecialMedicalExpensesInfo">Piilota ohje</small></span>

                <transition name="fade">
                    <div v-if="showSpecialMedicalExpensesInfo" v-html="T('info_special_medical_expenses')"
                        class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showSpecialMedicalExpensesFields" :pre-open="true" :guardians="calculation.guardians"
                    :is-alternating-weeks="alternatingWeeksBoolean" :is-child-expense="true" :entity="child" type="cost"
                    entity-type="children" field="special_medical_expenses" />
            </td>
        </tr>
        <tr v-if="child.age < 10">
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showDayCareExpensesFields = !showDayCareExpensesFields">{{ capitalize(T('daycare_expenses')) }} &nbsp;
                <i v-if="!showDayCareExpensesFields" class="fa-solid fa-circle-plus"></i><i v-if="showDayCareExpensesFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showDayCareExpensesFields" @click="showDayCareExpensesInfo = !showDayCareExpensesInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showDayCareExpensesInfo">Näytä ohje</small><small v-if="showDayCareExpensesInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showDayCareExpensesInfo" v-html="T('info_daycare_expenses')" class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showDayCareExpensesFields" :pre-open="true" :guardians="calculation.guardians"
                    :is-alternating-weeks="alternatingWeeksBoolean" :is-child-expense="true" :entity="child" type="cost"
                    entity-type="children" field="daycare_expenses" />
            </td>
        </tr>
        <tr>
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showOtherSpecialCostsFields = !showOtherSpecialCostsFields">{{ capitalize(T('other_special_costs')) }} &nbsp;
                <i v-if="!showOtherSpecialCostsFields" class="fa-solid fa-circle-plus"></i><i v-if="showOtherSpecialCostsFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showOtherSpecialCostsFields" @click="showOtherSpecialCostsInfo = !showOtherSpecialCostsInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showOtherSpecialCostsInfo">Näytä ohje</small><small v-if="showOtherSpecialCostsInfo">Piilota ohje</small></span>

                <transition name="fade">
                    <div v-if="showOtherSpecialCostsInfo" v-html="T('info_other_special_costs')" class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showOtherSpecialCostsFields" :pre-open="true" :guardians="calculation.guardians"
                    :is-alternating-weeks="alternatingWeeksBoolean" :is-child-expense="true" :entity="child" type="cost"
                    entity-type="children" field="other_special_costs" />
            </td>
        </tr>
        <tr>
            <td class="breakdown-td" colspan="2">
                <strong class="tooltip-hl" @click="showChildIncomeFields = !showChildIncomeFields">{{ capitalize(T('child_income')) }} &nbsp;
                <i v-if="!showChildIncomeFields" class="fa-solid fa-circle-plus"></i><i v-if="showChildIncomeFields" class="fa-solid fa-circle-minus"></i></strong>
                <br/><span v-if="showChildIncomeFields" @click="showChildIncomeInfo = !showChildIncomeInfo"  style="cursor:pointer; margin-left:-5px"><o-icon class="appBlue" pack="fas" icon="info-circle"></o-icon> <small v-if="!showChildIncomeInfo">Näytä ohje</small><small v-if="showChildIncomeInfo">Piilota ohje</small></span>
                <transition name="fade">
                    <div v-if="showChildIncomeInfo" v-html="T('info_child_income')" class="info-paragraph">
                    </div>
                </transition>
                <BreakDown v-if="showChildIncomeFields" :pre-open="true" :entity="child" type="income" entity-type="children" field="child_income" />
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <o-button :disabled="disableDelete" variant="danger" @click="showModal = true">{{
                T('remove_child_from_calculation') }}</o-button>
            </td>
        </tr>
    </table>

    <o-modal v-model:active="showModal">
        <div class="content">
            <h3>Vahvista poisto</h3>
            <p>Haluatko varmasti poistaa lapsen: <b>{{ child.name }}</b> tältä laskelmalta?</p>
            <o-button variant="success" @click="remove">Kyllä</o-button>&nbsp;&nbsp;
            <o-button variant="danger" @click="showModal = false">Peruuta</o-button>
        </div>
    </o-modal>
</template>
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
import BreakDown from './Breakdown.vue';
export default {
    name: 'ChildForm',
    components: {
        BreakDown
    },
    props: [
        'childCode',
        'disableDelete'
    ],
    computed: {
        ...mapWritableState(useCalculationStore, { calculation: 'calculation', calculatedFields: 'calculatedFields', token: 'token', guardians: 'guardians' }),
        child() {
            return this.calculation.children.find(child => child.code == this.childCode);
        },
        alternatingWeeksBoolean: {
            get() {
                return Boolean(this.child.alternating_weeks);
            },
            set(newValue) {
                this.child.alternating_weeks = newValue ? 1 : 0;
            }
        },
        custodialGuardianIsCloseGuardianBoolean: {
            get() {
                return Boolean(this.child.custodial_guardian_id == this.closeGuardian.id && !this.child.alternating_weeks);
            },
            set() {
                this.child.custodial_guardian_id = this.closeGuardian.id;
                this.child.alternating_weeks = 0;
            }
        },

        custodialGuardianIsRemoteGuardianBoolean: {
            get() {
                return Boolean(this.child.custodial_guardian_id == this.remoteGuardian.id && !this.child.alternating_weeks);
            },
            set() {
                this.child.custodial_guardian_id = this.remoteGuardian.id;
                this.child.alternating_weeks = 0;
            }
        },
        childBenefitToCloseGuardianBoolean: {
            get() {
                return Boolean(this.child.child_benefit_getter_guardian_id == this.closeGuardian.id);
            },
            set() {
                this.child.child_benefit_getter_guardian_id = this.closeGuardian.id;
            }
        },
        childBenefitToRemoteGuardianBoolean: {
            get() {
                return Boolean(this.child.child_benefit_getter_guardian_id == this.remoteGuardian.id);
            },
            set() {
                this.child.child_benefit_getter_guardian_id = this.remoteGuardian.id;
            }
        },
        closeGuardian() {
            return this.calculation.guardians.find(guardian => guardian.type == 'close');
        },
        closeGuardianName() {
            let guardian = this.closeGuardian;
            //console.log('closeGuardianName', guardian);
            if (guardian) {
                return guardian.name;
            }
            return '';
        },
        remoteGuardian() {
            //console.log('remoteGuardian', this.guardians);
            return this.calculation.guardians.find(guardian => guardian.type == 'remote');
        },
        remoteGuardianName() {
            let guardian = this.remoteGuardian;
            //console.log('remoteGuardianName', guardian);
            if (guardian) {
                return guardian.name;
            }
            return '';
        },
    },
    methods: {
        getCloseGuardian() {
            return this.guardians.find(guardian => guardian.type == 'close');
        },
        remove() {
            this.$api.delete('child/' + this.child.code).then(response => {
                //console.log(response.data);
                let emitter = require('tiny-emitter/instance');
                emitter.emit('update-calculation');
                emitter.emit('child-removed', this.child.code);
            }).catch(error => {
                //console.log(error);
            });
        },
        update() {
            setTimeout(() => {
                //console.log('update', this.child);
                this.$api.put('child/' + this.child.code, this.child).then(response => {
                    //console.log(response.data);
                    let emitter = require('tiny-emitter/instance');
                    emitter.emit('update-calculation');
                }).catch(error => {
                    //console.log(error);
                });
            }, 100);

        },
        updateCloseGuardianShare() {
            this.child.close_guardian_basic_share = 100 - this.child.remote_guardian_basic_share;
            this.update();
        },
        updateRemoteGuardianShare() {
            this.child.remote_guardian_basic_share = 100 - this.child.close_guardian_basic_share;
            this.update();
        }
    },
    data() {
        return {
            // Syöttökenttien piilotukset
            showChildIncomeFields: false,
            showOtherSpecialCostsFields: false,
            showSpecialMedicalExpensesFields: false,
            showDayCareExpensesFields: false,
            // Infot
            showLivingInfo: false,
            showRemoteNightsInfo: false,
            showSpecialHobbiesCostsInfo: false,
            showSpecialMedicalExpensesInfo: false,
            showDayCareExpensesInfo: false,
            showOtherSpecialCostsInfo: false,
            showChildIncomeInfo: false,
            showModal: false
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
}

.cell {
    flex: 1;
    padding: 0px;
}
</style>