<template>
    <div class="row justify-content-xl-center fw-row hide-on-print">
        <div class="col-lg-12 logo-container text-center">
            <img class="logo"
                src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg"><br />
            <o-input class="elatuslaskelma-name-input" v-model="calculation.name" @blur="updateCalculation" />
            <input @change="updateCalculation" v-model="calculation.ahvenanmaa" type="checkbox" id="ahvenanmaa"><label class="ahvenanmaa-label" for="ahvenanmaa">{{ T('calculate_as_ahvenanmaa') }}</label>
            <div>
                <strong @click="showCalculationInfo = !showCalculationInfo" class="tooltip-hl"><o-icon class="appBlue" pack="fas" icon="info-circle">
                    </o-icon> Katso elastuslaskurin ohje</strong>
                <transition name="fade">
                    <div v-if="showCalculationInfo" v-html="T('info_calculation')" class="info-paragraph"></div>
                </transition>
            </div>
            <div class="newsbox" v-if="T('newsbox') != 'newsbox' && T('newsbox') != ''" v-html="T('newsbox')"></div>
            <o-button class="logout-btn" variant="danger" @click="logout" onclick="location.href='/';">Kirjaudu ulos &nbsp;<i class="fa-sharp fa-regular fa-arrow-right-from-bracket"></i></o-button>
            <o-button class="return-btn" variant="danger" onclick="location.href='/';"><i class="fa-sharp fa-regular fa-arrow-left"></i> &nbsp;Palaa takaisin</o-button>
        </div>
    </div>
    <div class="container">
    <div class="row calculation-row">
        <div class="col-lg-6 hide-on-print">
            <div class="inner-div">
                <h2><i class="fa-sharp fa-solid fa-children"></i> Lapset</h2>
                <o-button v-if="calculation.children.length < 7" @click="createNewChild"><o-icon pack="fas"
                        style="color: green;" icon="plus" size="small" /> &nbsp; Lisää lapsi</o-button>
                <o-tabs v-model="activeChildrenTab">
                    <o-tab-item v-for="(child, index) in calculation.children" :key="child.code" :value="index"
                        :label="child.name">
                        <ChildForm :childCode="child.code" :disableDelete="isRemoveChildrenDisabled" />
                    </o-tab-item>
                </o-tabs>
                <h2><i class="fa-sharp fa-solid fa-family-pants"></i> Vanhemmat</h2>
                <o-tabs position="is-centered" v-model="activeGuardianTab">
                    <o-tab-item v-for="(guardian, index) in calculation.guardians" :key="guardian.code" :value="index"
                        :label="guardian.name">
                        <div class="is-flex is-justify-content-center" style="padding-top:0px;">
                            <GuardianForm :guardianCode="guardian.code" :alternatingWeeks="alternatingWeeks" />
                        </div>
                    </o-tab-item>
                </o-tabs>
            </div>
        </div>
        <div class="col-lg-6">
            <o-button class="print-btn" onclick="printDiv()"><i class="fa-sharp fa-solid fa-print"></i> &nbsp;Tulosta</o-button>
            <div class="inner-div print-area">
                <img src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg" class="print-logo">
                <h2 class="hide-on-print"><i class="fa-sharp fa-solid fa-calculator-simple"></i> Yhteenveto</h2>
                <GuardianComparison :cross-living="cross_living" :housing_costs_rows="housing_costs_rows" :income_rows="income_rows"
                    :close="guardians.close_guardian" :remote="guardians.remote_guardian" :alternatingWeeks="alternatingWeeks" />
                <ChildTable :cross-living="cross_living" :total_income_assistance="total_income_assistance"
                    :income_assistance_per_child="income_assistance_per_child" :close="guardians.close_guardian"
                    :remote="guardians.remote_guardian" />
                    <table class="total-money">
                        <tr>
                            <th colspan="3" style="text-align: center;" v-if="!cross_living">
                                
                                Elatusavut yhteensä: {{ $EUR(Math.abs(total_alimony)) }} €/kk <strong @click="showTotalInfo = !showTotalInfo" class="tooltip-hl"><o-icon class="appWhite" pack="fas" icon="info-circle">
                    </o-icon></strong>
                <transition name="fade">
                    <div v-if="showTotalInfo" v-html="T('info_total')" class="info-paragraph"></div>
                </transition>
                            </th>
                            <th colspan="3" style="text-align: center;" v-if="cross_living">
                                Elatusavut yhteensä <strong @click="showTotalInfo = !showTotalInfo" class="tooltip-hl"><o-icon class="appWhite" pack="fas" icon="info-circle"></o-icon></strong><br>
                                 {{ guardians.close_guardian.name }} maksaa: {{ $EUR(Math.abs(guardians.close_guardian.pays)) }} €/kk<br>
                                 {{ guardians.remote_guardian.name }} maksaa: {{ $EUR(Math.abs(guardians.remote_guardian.pays)) }} €/kk<br>

                <transition name="fade">
                    <div v-if="showTotalInfo" v-html="T('info_total')" class="info-paragraph"></div>
                </transition>
                            </th>
                        </tr>
                    </table>
                    <br>
                    <small>{{ T('disclaimer') }}</small>
            </div>
        </div>
    </div>
    </div>
</template>
    
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
import GuardianForm from './Guardian.vue';
import ChildForm from './Child.vue';
//import ChildResultView from './ChildResultView.vue';
//import GuardianResult from './GuardianResult.vue';
//import AlimonyResult from './AlimonyResult.vue';
import GuardianComparison from './GuardianComparison.vue';
import ChildTable from './ChildTable.vue';
export default {
    name: 'ChildSupportCalculation',
    components: {
        ChildForm,
        GuardianForm,
        // ChildResultView,
        // GuardianResult,
        // AlimonyResult,
        GuardianComparison,
        ChildTable
    },
    data() {
        return {
            showCalculationInfo: false,
            cross_living: false,
            showTotalInfo: false,
            alternatingWeeks: false,
            guardians: {
                remote_guardian: {
                    name: '',
                    code: '',
                    type: 'remote'
                },
                close_guardian: {
                    name: '',
                    code: '',
                    type: 'close'
                }
            },
            ahvenanmaa: false,
            activeResultTab: 0,
            activeGuardianTab: 0,
            activeChildrenTab: 0,
            calculation666: {
                code: '',
                name: '',
                ahvenanmaa: false,
                children: [
                    {
                        name: '',
                        code: '',
                        age: 0,
                        breakdowns: []
                    }
                ],
                guardians: [
                    {
                        name: '',
                        code: '',
                        type: 'close'
                    },
                    {
                        name: '',
                        code: '',
                        type: 'remote'
                    },
                ]
            },
            calculations: [],
            calculatedFields2: [],
            newData: {},
            email: '',
            password: '',
            user: {
                name: '',
                email: '',
                password: '',
                password_confirmation: ''
            },
            income_rows: [],
            housing_costs_rows: [],
            total_alimony: 0,
            income_assistance_per_child: 0,
            total_income_assistance: 0,
        }
    },
    computed: {
        ...mapWritableState(useCalculationStore, ['calculation', 'calculatedFields', 'newBreakdown', 'settings', 'translations', 'children', 'token', 'guardians']),
        isAddBreakdownDisabled() {
            return this.newBreakdown.field != '';
        },
        isRemoveChildrenDisabled() {
            return this.calculation.children.length < 2;
        },
    },
    props: {
        msg: String
    },
    methods: {
        toEuro(value) {
            //return value.toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
            return value.toLocaleString('de-DE', { minimumFractionDigits: 2 });
        },
        toFloat(stringValue) {
            let formattedValue = stringValue.replace('.', '').replace(',', '.');
            return parseFloat(formattedValue);

        },
        updateCalculation() {
            let ahvenanmaa = this.calculation.ahvenanmaa ? 1 : 0;
            let updateObj = {
                name: this.calculation.name,
                ahvenanmaa: ahvenanmaa
            };
            //console.log('PUT', updateObj);
            this.$api.put('/calculation/' + this.calculation.code, updateObj).then(response => {
                //console.log(response.data);
                this.fetchCalculation();
            }).catch(error => {
                //console.log(error);
            });
        },
        deleteBreakdown(breakdown) {
            //console.log('deleteBreakdown', breakdown);
            this.$api.delete('breakdown/' + breakdown.id).then(response => {
                //console.log(response.data);
                this.fetchCalculation();
            }).catch(error => {
                //console.log(error);
            });
        },
        getTotal(entity, type, field) {
            if (this.calculatedFields?.[type]?.[entity.code]?.[field]) {
                return this.calculatedFields[type][entity.code][field];
            }
            return 0;
        },
        getBreakdowns(entity, field) {
            let breakdowns = []
            entity.breakdowns.forEach((breakdown) => {
                if (breakdown.field == field) {
                    breakdowns.push(breakdown);
                }
            });
            return breakdowns;
        },
        showAddBreakdown(field, children) {
            this.newBreakdown.field = field;
            this.newBreakdown.children = children;
            this.newBreakdown.amount = 0;
            this.newBreakdown.period = 1;
            this.newBreakdown.description = '';
            this.newBreakdown.paying_guardian = '';
        },
        addBreakdown() {
            //console.log(this.newBreakdown);
            this.$api.post('breakdown', this.newBreakdown).then(response => {
                //console.log(response.data);
                this.fetchCalculation();
                this.newBreakdown = {
                    children: '',
                    guardian: '',
                    field: '',
                    description: '',
                    amount: 0,
                    paying_guardian: '',
                    period: 1
                };
            }).catch(error => {
                //console.log(error);
            });
        },
        deleteCalculation(calculation) {
            //console.log(calculation);
        },
        openCalculation(calculation) {
            this.$router.push('/calculation/' + calculation.code)
        },
        whoAmI() {
            this.$api.get('whoami').then(response => {
                //console.log(response.data);
            }).catch(error => {
                //console.log(error);
            });

        },
        createNewChild() {
            this.$api.post('child', { calculation: this.calculation.code }).then(response => {
                //console.log(response.data);
                this.activeChildrenTab = this.calculation.children.length
                this.fetchCalculation();
            }).catch(error => {
                //console.log(error);
            });
        },
        createNewCalculation() {
            this.$api.post('calculation').then(response => {
                //console.log(response.data);
                this.newData = response.data;
            }).catch(error => {
                //console.log(error);
            });
        },
        fetchSettings() {
            this.$api.get('settings').then(response => {
                //console.log(response.data);
                this.settings = response.data;
            }).catch(error => {
                //console.log(error);
            });
        },
        fetchTranslations() {
            this.$api.get('translations').then(response => {
                //console.log(response.data);
                this.translations = response.data;
            }).catch(error => {
                //console.log(error);
            });
        },
        fetchCalculations() {
            this.$api.get('calculation').then(response => {

                //console.log(response.data);
                this.calculations = response.data;

            }).catch(error => {
                //console.log(error);
            });
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('encryption_key');
            this.token = false;
            this.user = false;
            this.whoami = false;
        },
        fetchCalculation() {
            this.$api.get('calculation/' + this.$route.params.code).then(response => {
                //console.log(response.data);
                this.cross_living = response.data.cross_living;
                this.income_assistance_per_child = response.data.income_assistance_per_child;
                this.total_income_assistance = response.data.total_income_assistance;
                this.guardians.close_guardian = response.data.guardianA;
                this.guardians.remote_guardian = response.data.guardianB;
                this.alternatingWeeks = response.data.using_alternating_weeks;
                this.calculation = response.data.calculation;
                if (response.data.calculation.ahvenanmaa == 1) {
                    this.calculation.ahvenanmaa = true;
                }
                /*
                this.calculatedFields = {
                    children: response.data.children,
                    guardians: [response.data.guardianA, response.data.guardianB],
                    close: response.data.close_guardianA,
                    remote: response.data.remote_guardianB
                }
                */
               this.calculatedFields = response.data.calculated;
                this.children = response.data.children;
                this.newBreakdown = {
                    children: '',
                    guardian: '',
                    field: '',
                    description: '',
                    amount: 0,
                    period: 1,
                    paying_guardian: ''
                };
                this.total_alimony = response.data.total_alimony;
                // Käsitellään tulorivit esitysmuotoon
                this.income_rows = [];
                Object.entries(response.data.income_rows).forEach(([key, value]) => {
                    let tmpObj = {
                        key: key,
                        title: '',
                        close: '0,00',
                        remote: '0,00'
                    }

                    if (value.close !== undefined) {
                        tmpObj.title = value.close.description;
                        tmpObj.close = value.close.per_month;
                    }
                    if (value.remote !== undefined) {
                        if (tmpObj.title == '') {
                            tmpObj.title = value.remote.description;
                        }
                        tmpObj.remote = value.remote.per_month;
                    }
                    this.income_rows.push(tmpObj);
                });

                // Käsitellään asumiskulut esitysmuotoon
                this.housing_costs_rows = [];
                Object.entries(response.data.housing_costs_rows).forEach(([key, value]) => {
                    let tmpObj = {
                        key: key,
                        title: '',
                        close: 0,
                        remote: 0
                    }

                    if (value.close !== undefined) {
                        tmpObj.title = value.close.description;
                        tmpObj.close = value.close.per_month;
                    }
                    if (value.remote !== undefined) {
                        if (tmpObj.title == '') {
                            tmpObj.title = value.remote.description;
                        }
                        tmpObj.remote = value.remote.per_month;
                    }
                    this.housing_costs_rows.push(tmpObj);
                });

            }).catch(error => {
                //console.log(error);
            });
        },
    },
    created() {
        this.$store = useCalculationStore();
    },
    mounted() {
        this.token = localStorage.getItem('token');
        if (this.token) {
            this.fetchTranslations();
            this.fetchSettings();
            //this.fetchCalculations();
            this.fetchCalculation();
        }
        //this.user = JSON.parse(localStorage.getItem('user'));
        let emitter = require('tiny-emitter/instance');
        emitter.on('update-calculation', () => {
            //console.log("update-calculation");
            this.fetchCalculation();
        });
        emitter.on('child-removed', () => {
            this.activeChildrenTab = 0;
        });
    }
}
</script>
    