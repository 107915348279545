<template>
    <HeaderLogo />
    <div class="row justify-content-xl-center page-terms">
        <div class="col-xl-12 content">

            <h1>eLaskuri.fi -palvelun tietosuojaseloste</h1>
            <br>
            <h2>REKISTERINPITÄJÄ </h2>
            <p>Y-tunnus: 2215889-6<br />
                Osoite: Rantakatu 21, 67100 Kokkola<br />
                Yhteyshenkilö: Mika Talviaro<br />
                Sähköposti: asiakaspalvelu@elaskuri.fi</p>
                <br>
            <h2>1. YLEISTÄ </h2>
            <p>Tämä tietosuojaseloste koskee eLaskuri.fi -internetpalvelua (jäljempänä "eLaskuri").<br/>Päivitetty viimeksi 10.4.2024.</p>
            <br>
            <h2>2. SOPIMUS HENKILÖTIETOJEN KÄSITTELYSTÄ</h2>
            <p>eLaskuri.fi -palvelun tietokantaan tai käyttäjän itse syöttämien henkilötietojen osalta käyttäjä katsotaan rekisterinpitäjäksi ja eLaskuri.fi henkilötietojen käsittelijäksi.</p>
            <p>eLaskuri.fi ei voi ilman käyttäjän antamaa erillistä valtuutusta tai lain asettamaa velvoitetta tarkastella, muokata tai poistaa käyttäjän tilillä olevaa asiakirjasisältöä.</p>
            <p>Käyttäjä vastaa siitä, että hänellä on laskelmaa laatiessa oikeus käsitellä niitä henkilötietoja, jotka syötetään palveluun.</p>
            <br>
            <h2>3. HENKILÖTIETOJEN KÄSITTELYN TARKOITUKSET JA PERUSTE</h2>
            <p>Henkilötietoja käytetään eLaskuri.fi:n käyttäjien yksilöimiseen sekä palvelun ja käyttäjien välisen sopimuksen täyttämiseen. Henkilötietoja käytetään eLaskuri.fi:n käyttäjätiedotteisiin, käyttäjätilastoihin, palvelun kehittämiseen ja markkinointiin.</p>
            <p>Henkilötietojen käsittely perustuu eLaskuri.fi:n kanssa palvelun tilaamisen yhteydessä solmittuun palvelusopimukseen. Henkilötietojen käsittely voi perustua myös käyttäjien, rekisteröitymisen jälkeen tapahtuvien, eLaskuri.fi:n kanssa solmimiin erillisiin palvelusopimuksiin.</p>
            <br>
            <h2>4. KÄSITELTÄVÄT HENKILÖTIEDOT - Elatuslaskuri</h2>
            <p>
                Käsittelemme yhtä tai useampaa seuraavista käyttäjän henkilötiedoista: <br>
                <ul><li>Lasten ja vanhempien etunimet (laskurin käyttö ei edellytä sukunimen tai henkilötunnuksen syöttämistä), iät, sukulais- ja perhesuhdetiedot, tiedot tuloista, menoista, varoista, veloista ja saatavista, evästeiden avulla kerätyt tekniset tiedot, palvelun käytöstä kerätyt tekniset tiedot ja maksutiedot. </li></ul>
            </p>
            <br>
            <h2>5. TIETOLÄHTEET</h2>
            <p>Henkilötiedot kerätään suoraan käyttäjältä, käyttäjän lisäämistä tiedoista tai käyttäjän evästeiden avulla.</p>
            <br>
            <h2>6. HENKILÖTIETOJEN KÄSITTELIJÄT</h2>
            <p>Pääsy henkilötietoihin on vain henkilöillä, joilla on työnsä tai toimensa puolesta peruste päästä käsiksi henkilötietoihin.</p>
            <p>Henkilötietoja saatetaan teknisistä syistä siirtää Euroopan Unionin tai Euroopan talousalueen ulkopuolelle. Siirron edellytys on, että Euroopan komissio on todennut kohdemaan tietosuojan tason riittäväksi tai tietoja Euroopan Unionin ulkopuolella vastaanottava osapuoli sitoutuu tietosuojalainsäädännössä edellytettyihin asianmukaisiin suojatoimiin.</p>
            <br>
            <h2>7. HENKILÖTIETOJEN SÄILYTYSAIKA</h2>
            <p>Henkilötietoja säilytetään pääsääntöisesti niin kauan kuin käyttäjällä on käyttäjätili eLaskuri.fi:ssä. Tilin poistoa voi pyytää asiakaspalvelusta.</p>
            <br>
            <h2>8. HENKILÖTIETOJEN LUOVUTUS</h2>
            <p>Tietoja ei luovuteta säännönmukaisesti. Tietoja ei siirretä EU:n tai ETA:n ulkopuolelle.</p>
            <br>
            <h2>9. TEKNISET JA ORGANISATORISET TURVATOIMET</h2>
            <p>eLaskuri.fi:llä on käytössä asianmukaiset tekniset, organisatoriset ja hallintoon liittyvät tietoturvallisuusmenettelyt, joilla suojaamme kaikkia hallussamme olevia tietoja katoamisen, väärinkäytön, luvattoman käytön, luovuttamisen, muutosten ja tuhoamisen varalta.</p>
            <p>Sähköisessä muodossa olevat tiedot ovat teknisesti ja organisatorisesti suojattu ulkopuoliselta käytöltä. Käyttäjän oikeudet tietojärjestelmien käyttöön varmistetaan käyttövaltuushallinnalla.  Käyttäjä kirjautuu tietojärjestelmään varmennetta käyttäen. Käyttäjän kirjautuminen varmennetaan kaksivaiheisella (SMS) varmenteella.  

Tietoja käsitellään vain Suomessa olevilla palvelimilla. Kaikki järjestelmät sijaitsevat korkean turvaluokituksen konesaleissa, joissa on 24/7 valvottu palvelinympäristö sekä päivittäinen varmuuskopiointi tiedostoista ja tietokannoista.  

eLaskuri.fi:ssa oikeudet tietojärjestelmien käyttöön varmistetaan käyttövaltuushallinnalla. Käsittelyyn oikeutetut kirjautuvat tietojärjestelmään varmennetta käyttäen. Käsittelyyn oikeutetun kirjautuminen varmennetaan kaksivaiheisella (SMS) varmenteella.  </p>
            <br>
            <h2>10. REKISTERÖIDYN OIKEUDET</h2>
            <p>Rekisteröidyn oikeuksiin liittyvissä asioissa käyttäjä voi olla yhteydessä sähköpostilla osoitteeseen asiakaspalvelu@elaskuri.fi.</p>
            <p>eLaskuri.fi ei vastaa käyttäjiensä käyttäjätileilleen tuottamasta asiakirjasisällöstä. Luottamussyistä eLaskuri.fi ei tarkastele näitä aineistoja eikä eLaskuri.fi voi ulottaa alla olevia toimenpiteitä käyttäjätileillä olevan sisällön mahdollisesti sisältämiin henkilötietoihin.</p>
            <p>Rekisteröidyllä on seuraavat oikeudet: oikeus tarkastaa ja päästä käsiksi tietoihin, oikaisu- ja poisto-oikeus, oikeus käsittelyn rajoittamiseen, vastustamisoikeus ja valitusoikeus.</p>
            <br>
            <h2>11. YHTEYDENOTTO ELASKURIIN TAI TIETOSUOJAVIRANOMAISEEN</h2>
            <p>Jos sinulla on kysyttävää tietosuojaselosteesta, voit ottaa yhteyttä eLaskuri.fi:n asiakaspalveluun tai suoraan eLaskuri.fi:n tietosuojavastaavaan sähköpostitse osoitteeseen asiakaspalvelu@elaskuri.fi. Voit myös tehdä valituksen tai ottaa yhteyttä tietosuojaviranomaiseen lähettämällä sähköpostia osoitteeseen tietosuoja@om.fi.</p>

        </div>
        <FooterNav />
    </div>
</template>
<script>
import FooterNav from '@/components/FooterNav.vue'
import HeaderLogo from '@/components/HeaderLogo.vue'
export default {
    name: 'PrivacyPolicy',
    components: {
        FooterNav,
        HeaderLogo
    },
}
</script>