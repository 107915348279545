<template>
    <div class="breakdown-wrapper">
        <!--<o-button variant="info"  iconPack="fas" icon-left="print">Tulosta</o-button>-->
        <table class="results-table">
            <thead>
                <tr>
                    <th>
                        <h2><i class="fa-sharp fa-solid fa-family-pants"></i> Vanhemmat</h2>
                    </th>
                    <th>
                    {{ close.name }}
                    </th>
                    <th>
                    {{ remote.name }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- Tulot -->
                <tr v-if="close.income > 0 || remote.income > 0 ">
                    <th colspan="3">{{ T('income') }}</th>
                </tr>
                <tr v-for="incomeRow in income_rows" :key="incomeRow.key">
                    <th>{{ incomeRow.title }}</th>
                    <td>{{ $EUR(incomeRow.close) }} €/kk</td>
                    <td>{{ $EUR(incomeRow.remote) }} €/kk</td>
                </tr>
                <tr v-if="parseFloat(close.single_parent_benefit) > 0 || parseFloat(remote.single_parent_benefit) > 0">
                    <th>{{ T('single_parent_benefit') }}</th>
                    <td>{{ $EUR(close.single_parent_benefit) }} €/kk</td>
                    <td>{{ $EUR(remote.single_parent_benefit) }} €/kk</td>
                </tr>
                <tr v-if="close.income > 0 || remote.income > 0 ">
                    <th>{{ T('total') }}</th>
                    <td>{{ $EUR(close.income) }} €/kk</td>
                    <td>{{ $EUR(remote.income) }} €/kk</td>
                </tr>

                <!-- Asumiskulut -->
                <tr v-if="getTotal(close,'guardians','housing_costs') > 0 || getTotal(remote,'guardians','housing_costs') > 0 ">
                    <th colspan="3">{{ T('housing_costs_with_costs') }}</th>
                </tr>
                <tr v-for="housingCostRow in housing_costs_rows" :key="housingCostRow.key">
                    <th>{{ housingCostRow.title }}</th>
                    <td>{{ $EUR(housingCostRow.close) }} €/kk</td>
                    <td>{{ $EUR(housingCostRow.remote) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','housing_benefit') > 0 || getTotal(remote,'guardians','housing_benefit') > 0 ">
                    <th>{{ T('housing_benefit') }}</th>
                    <td>{{ $EUR(getTotal(close,'guardians','housing_benefit')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(remote,'guardians','housing_benefit')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','housing_costs') > 0 || getTotal(remote,'guardians','housing_costs') > 0 ">
                    <th>{{ T('total') }}</th>
                    <td>{{ $EUR(close.housing_costs) }} €/kk</td>
                    <td>{{ $EUR(remote.housing_costs) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','housing_costs') > 0 || getTotal(remote,'guardians','housing_costs') > 0 ">
                    <td>{{ T('housing_costs_guardian_share') }}</td>
                    <td>{{ $EUR(close.own_part_housing_costs) }} €/kk ({{ $EUR(close.own_part_of_housing_costs_percent) }}%)</td>
                    <td>{{ $EUR(remote.own_part_housing_costs) }} €/kk ({{ $EUR(remote.own_part_of_housing_costs_percent) }}%)</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','housing_costs') > 0 || getTotal(remote,'guardians','housing_costs') > 0 ">
                    <td>Lapsen/lasten osuus asumiskustannuksista</td>
                    <td>{{ $EUR(close.housing_costs_part_to_children) }} €/kk ({{ $EUR(close.housing_costs_part_to_children_percent) }}%)</td>
                    <td>{{ $EUR(remote.housing_costs_part_to_children) }} €/kk ({{ $EUR(remote.housing_costs_part_to_children_percent) }}%)</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','housing_costs') > 0 || getTotal(remote,'guardians','housing_costs') > 0 ">
                    <td>Vanhemmalle jää asumiskustannuksista</td>
                    <td>{{ $EUR(close.guardian_living_cost_left) }} €/kk ({{ $EUR(close.guardian_living_cost_left_percent) }}%)</td>
                    <td>{{ $EUR(remote.guardian_living_cost_left) }} €/kk ({{ $EUR(remote.guardian_living_cost_left_percent) }}%)</td>
                </tr>
              
                <!-- Vanhemman muut menot -->
                <tr>
                    <th colspan="3">Vanhemman muut menot</th>
                </tr>
                <tr>
                    <td>{{ T('cost_of_living') }}</td>
                    <td>{{ $EUR(close.cost_of_living) }} €/kk</td>
                    <td>{{ $EUR(remote.cost_of_living) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','commuting_expenses') > 0 || getTotal(remote,'guardians','commuting_expenses') > 0 ">
                    <td>{{ T('commuting_expenses') }}</td>
                    <td>{{ $EUR(getTotal(close,'guardians','commuting_expenses')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(remote,'guardians','commuting_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="close.commuting_expenses_km > 0 || remote.commuting_expenses_km > 0">
                    <td>Työmatkakulut oman auton käytön mukaan</td>
                    <td>{{ $EUR(close.commuting_expenses_km) }} €/kk</td>
                    <td>{{ $EUR(remote.commuting_expenses_km) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','special_medical_expenses') > 0 || getTotal(remote,'guardians','special_medical_expenses') > 0 ">
                    <td>{{ T('special_medical_expenses') }}</td>
                    <td>{{ $EUR(getTotal(close,'guardians','special_medical_expenses')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(remote,'guardians','special_medical_expenses')) }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','student_loan_costs') > 0 || getTotal(remote,'guardians','student_loan_costs') > 0 ">
                    <td>{{ T('student_loan_costs') }}</td>
                    <td>{{ $EUR(getTotal(close,'guardians','student_loan_costs')) }} €/kk</td>
                    <td>{{ $EUR(getTotal(remote,'guardians','student_loan_costs')) }} €/kk</td>
                </tr>
                <tr v-if="hasTotal('close','other_child_support_obligation') || hasTotal('remote','other_child_support_obligation')">
                    <td>{{ T('other_child_support_obligation') }}</td>
                    <td>{{ hasTotal('close','other_child_support_obligation') ? $EUR(hasTotal('close','other_child_support_obligation')) : '0,00' }} €/kk</td>
                    <td>{{ hasTotal('remote','other_child_support_obligation') ? $EUR(hasTotal('remote','other_child_support_obligation')) : '0,00' }} €/kk</td>
                </tr>
                <tr v-if="getTotal(close,'guardians','visitation_expenses') > 0 || getTotal(remote,'guardians','visitation_expenses') > 0 ">
                    <td>{{ T('visitation_expenses') }}</td>
                    <td></td>
                    <td>{{ $EUR(getTotal(remote,'guardians','visitation_expenses')) }} €/kk</td>
                </tr>
                <tr>
                    <th>{{ T('total') }}</th>
                    <td>{{ $EUR(close.outcome_without_housing) }} €/kk</td>
                    <td>{{ $EUR(remote.outcome_without_housing) }} €/kk</td>
                </tr>

                <!-- Elatuskyky -->
                <tr>
                    <th>Elatuskyky</th>
                    <td>
                        <span>{{ $EUR(close.capacity) }} €/kk ({{ $EUR(close.percent) }}%)</span>
                    </td>
                    <td>
                        <span>{{ $EUR(remote.capacity) }} €/kk ({{ $EUR(remote.percent) }}%)</span>
                    </td>
                </tr>
                <tr v-if="alternatingWeeks">
                    <th>Elatuskyky kun vuoroasuvan lapsen/lasten osuutta asumiskuluista ei huomioida</th>
                    <td>
                        <span>{{ $EUR(close.capacity_without_alts) }} €/kk</span>
                    </td>
                    <td>
                        <span>{{ $EUR(remote.capacity_without_alts) }} €/kk</span>
                    </td>
                </tr>
                <tr v-if="1 == 2">
                    <td colspan="3">
                        Laskurilla on laskettu elatusapuja siten, että lapsia asuu ristiin vanhempiensa luona. Ristiin menevää elatusvastuuta ei ole vähennetty elatuskyvystä.
                    </td>
                </tr>
            </tbody>
        </table>
        <!--<pre>{{ close }}</pre>-->
    </div>
</template>
<script>
import { mapWritableState } from 'pinia'
import { useCalculationStore } from '@/stores/CalculationStore.js'
 export default {
    name: 'GuardianResult',
    components: {
    },
    props: [
        'close',
        'remote',
        'income_rows',
        'housing_costs_rows',
        'income_assistance_per_child',
        'crossLiving',
        'alternatingWeeks'
    ],
    computed: {
        ...mapWritableState(useCalculationStore, { guardians: 'guardians', calculation: 'calculation', calculatedFields: 'calculatedFields', children: 'children'}),
    },
    methods: {
        hasTotal(type, field) {
            if(type == "close") {
                if(field == 'other_child_support_obligation') {
                    let total = 0;
                    if(this.close.totals && this.close.totals[field]) {
                        total += this.close.totals[field];
                    }
                    return total + this.close.otherChildSupportTotalCost;
                }
                if(this.close.totals && this.close.totals[field]) {
                    return this.close.totals[field];
                }
            } 
            else {
                if(field == 'other_child_support_obligation') {
                    let total = 0;
                    if(this.remote.totals && this.remote.totals[field]) {
                        total += this.remote.totals[field];
                    }
                        return total + this.remote.otherChildSupportTotalCost;
                    }
                if(this.remote.totals && this.remote.totals[field]) {
                    return this.remote.totals[field];
                }
            }
            return false;
        },
        getBreakdowns(entity, field) {
            let breakdowns = []
            entity.breakdowns.forEach((breakdown) => {
                if (breakdown.field == field) {
                    breakdowns.push(breakdown);
                }
            });
            return breakdowns;
        },
        getTotal(entity, type, field) {
            if(this.calculatedFields?.[type]?.[entity.code]?.[field]) {
                return this.calculatedFields[type][entity.code][field];
            }
            return 0;
        },
    },
    data() {
      return {}
    }
}
</script>