<template>
  <tr v-if="state.field == field">
    <td v-if="!isAlternatingWeeks" colspan="2" class="custom-width">
      <o-input @blur="updateBreakdown" v-model="state.description" type="text" />
    </td>
    <td v-if="isAlternatingWeeks" colspan="1">
      <o-input @blur="updateBreakdown" v-model="state.description" type="text" />
    </td>
    <td v-if="isAlternatingWeeks">
      <o-select @change="updateBreakdown" v-model="state.paying_guardian" style="width: 100%">
        <option v-for="guardian in guardians" :key="guardian.code" :value="guardian.code">{{
          guardian.name }}</option>
      </o-select>
    </td>
    <td>
      <o-input @blur="updateBreakdown" v-model="state.amount" type="number" />
    </td>
    <td>
      <o-select @change="updateBreakdown" v-model="state.period">
        <option value="0.5">2 vko</option>
        <option value="1">1 kk</option>
        <option value="2">2 kk</option>
        <option value="3">3 kk</option>
        <option value="4">4 kk</option>
        <option value="5">5 kk</option>
        <option value="6">6 kk</option>
        <option value="7">7 kk</option>
        <option value="8">8 kk</option>
        <option value="9">9 kk</option>
        <option value="10">10 kk</option>
        <option value="11">11 kk</option>
        <option value="12">12 kk</option>
      </o-select>
    </td>
    <td>
      <o-button variant="danger" @click="deleteBreakdown(state)">
        <i class="fa-sharp fa-solid fa-trash-xmark"></i>
      </o-button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "EditBreakdown",
  props: [
    'isChildExpense',
    'isAlternatingWeeks',
    'guardians',
    'field',
    'entityType',
    'entity',
    'type',
    'preOpen',
    'fillData'
  ],
  data() {
    return {
      state: {}
    }
  },
  mounted() {
    this.state = this.fillData;
  },
  methods: {
    updateBreakdown() {
      //console.log("Updateing breakdown", this.state);
      this.$api.put('breakdown/' + this.state.id, this.state).then((response) => {
        //console.log('Breakdown response', response.data);
        let emitter = require('tiny-emitter/instance');
        emitter.emit('update-calculation');
        this.state = JSON.parse(JSON.stringify(this.emptyState));
      }).catch(error => {
        //console.log(error);
      });
    },
    deleteBreakdown(breakdown) {
      //console.log('deleting', breakdown);
      this.$api.delete('breakdown/' + breakdown.id).then(response => {
        //console.log(response.data);
        let emitter = require('tiny-emitter/instance');
        emitter.emit('update-calculation');
      }).catch(error => {
        //console.log(error);
      });
    },
  }
}
</script>

<style scoped></style>