<template>
    <HeaderLogo />
    <div class="row justify-content-xl-center page-terms">
        <div class="col-xl-12 content">

            <h1>Tilaa palvelu</h1>
            <p>Valitse tilaamasi palvelut</p>
            <input disabled type="checkbox" v-model="order.services.elatuslaskuri"> Elatuslaskuri
            <br>
            <br>
            <div v-if="order.services.elatuslaskuri">
                <h2>TILAAN ELATUSLASKURIN</h2>
                <select v-model="order.userCount" @change="updateUserArray">
                    <option value="1">1 käyttäjälle</option>
                    <option value="2">2 käyttäjälle</option>
                    <option value="3">3 käyttäjälle</option>
                    <option value="4">4 käyttäjälle</option>
                    <option value="5">5 käyttäjälle</option>
                    <option value="6">6+ käyttäjälle</option>
                </select>
                <br>
                <h2>PALVELUN SISÄLTÖ</h2>
                Elatuslaskuri seuraavilla ominaisuuksilla:
                <ul>
                    <li>Laskuriin syötettyjen tietojen tallennus, joka mahdollistaa laskennan jatkamisen myöhemmin</li>
                    <li>Laskelman monistus</li>
                    <li>Tulojen/kulujen nimeäminen</li>
                    <li>Tulojen/kulujen automaattinen jaksotus (voit syöttää esimerkiksi 12 kuukauden tulon, jonka
                        laskuri jakaa yhdelle kuukaudelle)</li>
                    <li>Vuoroasumisen laskenta</li>
                    <li>Muun elatusvastuun automaattinen huomioiminen</li>
                    <li>Lähivanhemmuuden vaihto </li>
                    <!--<li>Lasten asuminen ristiin vanhempiensa luona</li>-->
                </ul>
                <br />
                <h2>HINTA (Hyvinvointialueet, oikeusaputoimistot ja tuomioistuimet)</h2>
                <p>Lähetä meille sähköpostia <a
                        href="mailto:asiakaspalvelu@elaskuri.fi">asiakaspalvelu@elaskuri.fi</a> niin teemme Sinulle
                    tarjouksen.</p>
                <h2>HINTA (Asianajo- ja lakiasiaintoimistot)</h2>
                <p>1-5 käyttäjää 39 €/käyttäjä/kk</p>
                <p>Lisäksi veloitetaan palvelun avausmaksuna 50 €.</p>
                <p>Hintoihin lisätään arvonlisävero. </p>
                <p>Mikäli organisaatiossasi on 6 käyttäjää tai enemmän, lähetä meille sähköpostia <a
                        href="mailto:asiakaspalvelu@elaskuri.fi">asiakaspalvelu@elaskuri.fi</a> niin teemme Sinulle
                    tarjouksen.</p>
                <br />
                <span v-if="order.userCount < 6">
                    <h2>KÄYTTÄJÄTIEDOT</h2>
                    <table>
                        <tr>
                            <th>Etunimi</th>
                            <th>Sukunimi</th>
                            <th>Sähköposti</th>
                            <th>Puhelin</th>
                        </tr>
                        <tr v-for="(user, index) in order.users" :key="'user-' + index">
                            <td><input v-model="user.first_name" type="text"></td>
                            <td><input v-model="user.last_name" type="text"></td>
                            <td><input v-model="user.email" type="text"></td>
                            <td><input v-model="user.phone" type="text"></td>
                        </tr>
                    </table>
                    <br />
                </span>

                <h2>YHTEYSTIEDOT</h2>
                Etunimi: <input v-model="customer.first_name" type="text"><br>
                Sukunimi: <input v-model="customer.last_name" type="text"><br>
                Tehtävänimike: <input v-model="customer.title" type="text"><br>
                Organisaatio: <input v-model="customer.organization" type="text"><br>
                Sähköposti: <input v-model="customer.email" type="text"><br>
                Puhelin: <input v-model="customer.phone" type="text"><br>
                Osoite: <input v-model="customer.address" type="text"><br>
                Postinumero: <input v-model="customer.postcode" type="text"><br>
                Postitoimipaikka: <input v-model="customer.city" type="text"><br>
                <br>
                <h2>VERKKOLASKUTUSTIEDOT </h2>
                Yksikkö tai maksuviite: <input v-model="customer.department" type="text"><br>
                Operaattoritunnus: <input v-model="customer.ebilling_operator_code" type="text"><br>
                Verkkolaskuosoite: <input v-model="customer.ebilling_address" type="text"><br>
                <br>
                <span v-if="!isOfferRequest">
                    <h2>TILAUSVAHVISTUS</h2>
                    <p>Alla näet tilaamasi palvelut hintoineen. Vahvista tilaus Tilaa -painikkeella.</p>
                    Tilattavat palvelut:
                    <ul>
                        <li>Elatuslaskuri</li>
                    </ul>
                    {{ order.userCount }} käyttäjää: {{ total }} € + alv 24%
                    <br>
                    Verollinen hinta: {{ totalWithVat }} €
                    <br><br>
                    Palvelun avausmaksu:
                    50,00 € + alv 24%<br>
                    Verollinen hinta: 62,00 €
                    <br><br>
                    Yhteensä {{ withOpeningTotal }} € + alv 24%<br>
                    <b>Verollinen hinta yhteensä: {{ withOpeningTotalWithVat }} €</b>
                    <br><br>
                    Kampanjakoodi <input v-model="order.campaing_code" type="text">
                    <div v-if="showCampaing">
                        <b>Kampanjakoodi on voimassa. Saat 50% alennuksen ensimmäisen tilausjakson hinnasta.</b>
                    </div>
                    <br />
                    Lisätiedot
                    <input type="text" v-model="order.info" />
                    <br />
                    <input type="checkbox" v-model="order.terms" /> Hyväksyn palvelun <a href="/#/terms"
                        target="_blank">käyttöehdot</a>.<br />
                    <input type="checkbox" v-model="order.privacy"> Olen lukenut palvelun <a href="/#/privacy"
                        target="_blank">tietosuojaselosteen</a>. <br />

                    Tilaus on toistaiseksi voimassa oleva kestotilaus tilausjakson ollessa 12 kk. Hinnat ovat vuoden
                    tilausjaksolta.<br />
                    <o-button v-if="!working" :disabled="isDisabled" variant="primary" @click="sendOrder">TILAA</o-button>
                    <o-button v-if="working" :disabled="isDisabled" variant="primary" @click="sendOrder">TILAA<i class="fas fa-spinner fa-spin"></i></o-button>
                    <div v-if="status == 'success'"><br><br><strong>Kiitos tilauksestasi!</strong></div>
                    <div v-if="status == 'error'" style="color: red;"><br><br><strong>Tilauksen lähetys epäonnistui. Ota yhteyttä <a href="mailto:asiakaspalvelu@elatuslaskuri.fi">asiakaspalvelu@elatuslaskuri.fi</a>.</strong></div>
                </span>
                <span v-if="isOfferRequest">
                    <h2>TARJOUSPYYNTÖ</h2>
                    Olet valinnut 6 käyttäjää tai enemmän. Saat tarjouksen sähköpostiisi. <br>

                    Sinulle sähköpostitse toimitettavassa tarjouksessa tilaus on voimassa toistaiseksi. Se on
                    kestotilaus, tilausjakson ollessa 12 kk. <br><br>
                    Lisätiedot
                    <input type="text" v-model="order.info" />
                    <br />
                    <input type="checkbox" v-model="order.terms" /> Hyväksyn palvelun <a href="/#/terms"
                        target="_blank">käyttöehdot</a>.<br />
                    <input type="checkbox" v-model="order.privacy"> Olen lukenut palvelun <a href="/#/privacy"
                        target="_blank">tietosuojaselosteen</a>. <br />
                    <o-button v-if="!working" :disabled="isDisabled" variant="primary" @click="sendOfferRequest">PYYDÄ
                        TARJOUS</o-button>
                    <o-button v-if="working" :disabled="isDisabled" variant="primary" @click="sendOfferRequest">PYYDÄ
                        TARJOUS<i class="fas fa-spinner fa-spin"></i></o-button>
                        
                    <div v-if="status == 'success'"><br><br><strong>Kiitos tarjouspyynnöstäsi!</strong></div>
                    <div v-if="status == 'error'" style="color: red;"><br><br><strong>Tarjouspyynnön lähetys epäonnistui. Ota yhteyttä <a href="mailto:asiakaspalvelu@elatuslaskuri.fi">asiakaspalvelu@elatuslaskuri.fi</a>.</strong></div>
                </span>
            </div>
            <br>
        </div>
        <FooterNav />
    </div>
</template>
<script>
import HeaderLogo from './HeaderLogo.vue';
import FooterNav from './FooterNav.vue';
export default {
    name: 'OrderForm',
    components: {
        HeaderLogo,
        FooterNav
    },
    computed: {
        showCampaing() {
            return this.order.campaing_code == 'LAKI50';
        },
        isDisabled() {
            if(this.status == 'success') {
                return true;
            }
            if(this.customer.first_name == '') {
                return true;
            }
            if(this.customer.last_name == '') {
                return true;
            }
            if(this.customer.organization == '') {
                return true;
            }
            if(this.customer.email == '') {
                return true;
            }
            if(this.customer.mobile == '') {
                return true;
            }
            return !this.order.terms || !this.order.privacy;
        },
        isOfferRequest() {
            if (this.order.userCount > 5) {
                return true;
            }
            return false;
        },
        totalWithVat() {
            return this.$EUR(this.price * this.order.userCount * this.vatMultiplier * 12);
        },
        total() {
            return this.$EUR(this.price * this.order.userCount * 12);
        },
        withOpeningTotal() {
            return this.$EUR(this.price * this.order.userCount * 12 + 50);
        },
        withOpeningTotalWithVat() {
            return this.$EUR(this.price * this.order.userCount * this.vatMultiplier * 12 + 62);
        }
    },
    data() {
        return {
            working: false,
            status: 'none', // 'none', 'success', 'error
            openingFee: 50,
            price: 39,
            vatMultiplier: 1.24,
            customer: {
                first_name: '',
                last_name: '',
                organization: '',
                email: '',
                phone: '',
                address: '',
                postcode: '',
                city: '',
                department: '',
                ebilling_operator_code: '',
                ebilling_address: '',
            },
            newUser: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '+358',
            },
            order: {
                campaing_code: '',
                terms: false,
                privacy: false,
                info: '',
                services: {
                    elatuslaskuri: true
                },
                userCount: 1,
                users: [
                    {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '+358',
                    }
                ]
            }
        }
    },
    methods: {
        updateUserArray() {
            this.order.users = [];
            for (let i = 0; i < this.order.userCount; i++) {
                this.order.users.push({ ...this.newUser });
            }
        },
        sendOrder() {
            this.working = true;
            let data = {
                customer: this.customer,
                order: this.order
            }
            this.$api.post('order', data).then(response => {
                this.working = false;
                //console.log(response.data);
                this.status = 'success';
            }).catch(error => {
                this.status = 'error';
                this.working = false;
                //console.log(error);
            });
        },
        sendOfferRequest() {
            this.working = true;
            let data = {
                customer: this.customer,
                order: this.order
            }
            this.$api.post('offer-request', data).then(response => {
                this.working = false;
                this.status = 'success';
                //console.log(response.data);
            }).catch(error => {
                this.working = false;
                this.status = 'error';
                //console.log(error);
            });
        }
    }
}
</script>