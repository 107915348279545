import { createRouter, createWebHashHistory } from 'vue-router'

// import your component
import ElatusLaskuri from '../components/ElatusLaskuri.vue'
import Calculation from '../components/Calculation.vue'
import TermsPage from '../components/TermsPage.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import OrderForm from '../components/OrderForm.vue'
import TrialForm from '../components/TrialForm.vue'
import NewPassword from '../components/NewPassword.vue'
import ValmiaPage from '../components/ValmiaPage.vue'
// define routes
const routes = [
  { path: '/', component: ElatusLaskuri },
  { path: '/reset-password/:token/:email', component: NewPassword },
  { path: '/calculation/:code', component: Calculation },
  { name: 'terms', path: '/terms', component: TermsPage },
  { name: 'privacy', path: '/privacy', component: PrivacyPolicy },
  { name: 'order', path: '/order', component: OrderForm },
  { name: 'trial', path: '/trial', component: TrialForm },
  { name: 'valmia', path: '/valmia', component: ValmiaPage },
]

// create router
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
