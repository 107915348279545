<template>
   <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  mounted() {
    //console.log('App mounted', this);
  },
}
</script>
