<template>
    <FrontPage v-if="!whoami" />
    <div v-if="whoami" class="row fw-row">
        <div class="col-12 logo-container">
            <img class="logo"
                src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg"><br />
            <o-button v-if="whoami" class="create-new-btn" variant="success" @click="newCalculationModal = true">Luo
                uusi
                laskelma &nbsp;<i class="fa-sharp fa-solid fa-file-circle-plus" @click="newCopyModal"></i></o-button>
            <o-button v-if="whoami" class="logout-btn" variant="danger" @click="logout">Kirjaudu ulos &nbsp;<i
                    class="fa-sharp fa-regular fa-arrow-right-from-bracket"></i></o-button>
        </div>
    </div>
    <div v-if="whoami" class="row fw-row justify-content-xl-center">
        <div class="col-xl-8 table-col">
            <table class="table" v-if="whoami">
                <tr v-for="calculation in calculations" :key="calculation.code">
                    <td><router-link :to="'/calculation/' + calculation.code">{{ calculation.name }}</router-link></td>
                    <td>
                        <o-button variant="primary" class="open-laskelma" @click="openCalculation(calculation)">Avaa
                            elatuslaskelma</o-button>
                            <o-button @click="promptDuplicate(calculation)"><i class="fa-sharp fa-solid fa-copy"></i></o-button>
                        <o-button variant="danger" @click="promptDelete(calculation)">
                            <i class="fa-sharp fa-solid fa-trash-xmark"></i>
                        </o-button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <o-modal v-model:active="newCalculationModal">
        <div class="content">
            <h3>Uusi elatuslaskelma</h3>
            <p>Aloita uuden elatuslaskelman luonti syöttämällä laskelman, vanhemman a ja vanhemman b nimi.</p>
            <input placeholder="Elatuslaskelman nimi" type="text" v-model="newCalculation.name">
            <input placeholder="Vanhemman A nimi" type="text" v-model="newCalculation.closeGuardianName">
            <input placeholder="Vanhemman B nimi" type="text" v-model="newCalculation.remoteGuardianName">
            <o-button variant="primary" @click="createNewCalculation">Luo uusi laskelma</o-button>
        </div>
    </o-modal>
    <o-modal v-model:active="newCopyModal">
        <div class="content">
            <h3>Kopioi elatuslaskelma</h3>
            <p>Nimeä elatuslakselman kopio.</p>
            <input placeholder="Elatuslaskelman nimi" v-model="duplicate_name" type="text">
            <o-button @click="duplicateCalculation" variant="primary">Kopioi</o-button>
        </div>
    </o-modal>
    <o-modal v-model:active="deleteModal">
        <div class="content">
            <h3>Vahvista poisto</h3>
            <p>Haluatko varmasti poistaa laskelman: <b>{{ selectedCalculation.name }}</b>?</p>
            <o-button variant="success" @click="confirmDelete">Kyllä</o-button>&nbsp;&nbsp;
            <o-button variant="danger" @click="deleteModal = false">Peruuta</o-button>
        </div>
    </o-modal>
</template>

<script>
import FrontPage from '@/components/FrontPage.vue'
export default {
    name: 'ElatusLaskuri',
    components: {
        FrontPage 
    },
    data() {
        return {
            newCalculation: {
                name: '',
                remoteGuardianName: '',
                closeGuardianName: '',
            },
            newCalculationModal: false,
            newCopyModal: false,
            whoami: false,
            calculations: [],
            newData: {},
            token: '',
            key: 'no-key',
            deleteModal: false,
            selectedCalculation: null, // Placeholder for the calculation to be deleted
            user: {
                name: '',
                email: '',
                password: '',
                password_confirmation: ''
            },
          duplicate_from: '',
          duplicate_name: '',
        }
    },
    props: {
        msg: String
    },
    methods: {
      // Method to prompt deletion with confirmation
      promptDelete(calculation) {
          this.selectedCalculation = calculation;
          this.deleteModal = true;
      },
      promptDuplicate(calculation) {
        this.duplicate_name = calculation.name + " (kopio)";
        this.duplicate_from = calculation.code;
        this.newCopyModal = true;
      },
      duplicateCalculation() {
        this.$api.post('calculation/'+this.duplicate_from+'/duplicate',{name: this.duplicate_name}).then(response => {
          //console.log(response.data);
          this.newData = response.data;
          this.openCalculation(response.data);
        }).catch(error => {
          //console.log(error);
        });
      },

        // Method to actually perform the deletion
        confirmDelete() {
            this.$api.delete('calculation/' + this.selectedCalculation.code).then(response => {
                //console.log(response.data);
                this.fetchCalculations();
            }).catch(error => {
                //console.log(error);
            });
            this.deleteModal = false;
        },

        openCalculation(calculation) {
            this.$router.push('/calculation/' + calculation.code)
        },
        whoAmI() {
            this.$api.get('whoami').then(response => {
                //console.log(response.data);
                this.whoami = response.data;
                this.fetchCalculations();
            }).catch(error => {
                //console.log(error);
            });

        },
        createNewCalculation() {
            this.$api.post('calculation', this.newCalculation).then(response => {
                //console.log(response.data);
                this.newData = response.data;
                this.openCalculation(response.data.calculation);
            }).catch(error => {
                //console.log(error);
            });
        },
        fetchCalculations() {
            this.$api.get('calculation').then(response => {
                //console.log(response.data);
                this.calculations = response.data;
            }).catch(error => {
                //console.log(error);
            });
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('encryption_key');
            this.token = false;
            this.user = false;
            this.key = false;
            this.whoami = false;
            this.use_two_factor = false;
            this.two_factor_code = '';
        },
       
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.key = localStorage.getItem('encryption_key');
        if(this.token) {
            // Set the token as the default Authorization header
            this.$api.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            this.whoAmI();
        }
    }
}
</script>

  