<template>
    <div v-if="!password_reset" class="row justify-content-xl-center login-row">
        <div class="col-xl-4 intro-container">
            <img class="logo" src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg">
            <p>Palvelumme avulla voit laskea lapsen elatusavun nopeasti ja helposti. Palvelu on tarkoitettu
                lastenvalvojille, lakimiehille ja tuomioistuimille.</p>
                <p><br /></p>
                <o-button icon-pack="fas" icon-left="shopping-cart" variant="primary" @click="$toPage('order')">Tilaa palvelu</o-button>
                <o-button icon-pack="fas" icon-left="clock" variant="primary" @click="$toPage('trial')">Tutustu maksutta</o-button>
        </div>
        <div class="col-xl-3">
            <h2><i class="fa-sharp fa-solid fa-key"></i> Kirjaudu sisään</h2>
            <input class="input" placeholder="Sähköpostiosoite" type="text" v-model="email" @keyup.enter="login">
            <input class="input" placeholder="Salasana" type="password" v-model="password" @keyup.enter="login">
            <input v-if="use_two_factor" class="input" placeholder="Turvakoodi" maxlength="4" type="text"
                v-model="two_factor_code" @keyup.enter="login">
            <!--<div class="error-message">Sähköpostiosoite on virheellinen</div>-->
            <span style="color:red" v-if="loginFailed && !use_two_factor">Kirjautuminen epäonnistui!<br></span>
            <span style="color:orange" v-if="loginFailed && use_two_factor">Kaksivaiheinen kirjautuminen käytössä!<br></span>
            <o-button v-if="!loginIsActive" variant="primary" @click="login">Kirjaudu</o-button>
            <o-button v-if="loginIsActive" variant="primary" @click="login" :disabled="true">Kirjaudu <i
                    class="fas fa-spinner fa-spin"></i></o-button>
                    <span @click="password_reset = true" style="text-align:right; display:block; margin-top: -10px; cursor: pointer;">Unohtuiko salasana?</span>
        </div>
    </div>
    <div v-if="password_reset" class="row justify-content-xl-center login-row">
        <div class="col-xl-4 intro-container">
            <RouterLink to='/'><img class="logo" src="/wp-content/themes/elatuslaskenta/app/public/elaskuri-logo.svg"></RouterLink>
            <p>Palvelumme avulla voit laskea lapsen elatusavun nopeasti ja helposti. Palvelu on tarkoitettu
                lastenvalvojille, lakimiehille ja tuomioistuimille.</p>
                <p><br /></p>
                <o-button icon-pack="fas" icon-left="shopping-cart" variant="primary" @click="$toPage('order')">Tilaa palvelu</o-button>
                <o-button icon-pack="fas" icon-left="clock" variant="primary" @click="$toPage('trial')">Tutustu maksutta</o-button>
        </div>
        <div class="col-xl-3" v-if="!reset_sent">
            <h2><i class="fa-sharp fa-solid fa-key"></i> Vaihda salasana</h2>
            <p>Lähetämme sinulle sähköpostin, joka sisältää linkin salasanan muuttamista varten.</p>
            <p><br /></p>
            <p>Huomioi, että tietoturvallisuussyistä salasanan vaihtaminen johtaa siihen, että aiemmin tallentamistasi laskelmista katoavat sanalliset määritteet (laskelmien euromäärät säilyvät).</p>
            <p><br /></p>
            <input class="input" placeholder="Sähköpostiosoite" type="text" v-model="email" @keyup.enter="reset">
            <input v-if="use_two_factor" class="input" placeholder="Turvakoodi" maxlength="4" type="text"
                v-model="two_factor_code" @keyup.enter="reset">
            <!--<div class="error-message">Sähköpostiosoite on virheellinen</div>-->
            <o-button v-if="!working" variant="primary" @click="reset">LÄHETÄ</o-button>
            <o-button v-if="working" variant="primary" @click="reset" :disabled="true">LÄHETÄ <i
                    class="fas fa-spinner fa-spin"></i></o-button>
                    <span @click="password_reset = false" style="text-align:right; display:block; margin-top: -10px; cursor: pointer;">Kirjaudu sisään</span>
        </div>
        <div class="col-xl-3" v-if="reset_sent">
            <h2><i class="fa-sharp fa-solid fa-key"></i> Vaihda salasana</h2>
            <p>Sähköpostiisi on lähetetty linkki, joka sisältää ohjeet salasanan muuttamista varten.</p>
            <p><br /></p>
            <span @click="password_reset = false" style="text-align:right; display:block; margin-top: -10px; cursor: pointer;">Kirjaudu sisään</span>
            </div>
        </div>
    <div class="justify-content-xl-center">
        <br><br><br>
        <FooterNav />
    </div>

</template>
<script>
import FooterNav from './FooterNav.vue';
export default {
    components: {
        FooterNav
    },
    name: 'FrontPage',
    data() {
        return {
            loginFailed: false,
            reset_sent: false,
            working: false,
            password_reset: false,
            loginIsActive: false,
            two_factor_code: '',
            use_two_factor: false,
            email: '',
            password: '',
        }
    },
    methods: {
        reset() {
            this.reset_sent = false;
            this.working = true;
            this.$api.post('password-reset', {
                email: this.email,
            }).then(() => {
                this.working = false;
                this.reset_sent = true;
            }).catch(error => {
                this.working = false;
                //console.log("Reset failed")
                //console.log(error, error.response, error.response.data, error.response.data.errors, error.response.data.errors.state);
            });
            //alert('Salasanan vaihtolinkki ei lähde vielä');
            /*
            //console.log("Resetting password...");
            this.loginIsActive = true;
            this.$api.post('password/reset', {
                email: this.email,
                two_factor_code: this.two_factor_code,
            }).then(response => {
                this.loginIsActive = false;
                //console.log('Reset response', response.data);
                this.use_two_factor = false;
                this.two_factor_code = '';
                alert('Salasanan vaihtolinkki lähetetty sähköpostiisi');
            }).catch(error => {
                //console.log("Reset failed")
                this.loginIsActive = false;
                //console.log(error, error.response, error.response.data, error.response.data.errors, error.response.data.errors.state);
                if (error.response.data.errors.status && error.response.data.errors.status == 'pending') {
                    this.use_two_factor = true;
                }
            });
            */
        },
        login() {
            //console.log("Logging in...");
            this.loginFailed = false;
            this.loginIsActive = true;
            this.$api.post('login', {
                email: this.email,
                password: this.password,
                two_factor_code: this.two_factor_code,
            }).then(response => {
                this.loginIsActive = false;
                //console.log('Login response', response.data);
                this.encryption_key = response.data.encryption_key
                localStorage.setItem('encryption_key', response.data.encryption_key);

                // Set the token in local storage and state
                this.token = response.data.token;
                this.key = response.data.encryption_key;
                localStorage.setItem('token', response.data.token);

                // Set the token as the default Authorization header
                this.$api.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                this.$api.defaults.headers.common['X-Encryption-Key'] = this.key;

                // Now make the requests
                this.use_two_factor = false;
                this.two_factor_code = '';
                location.reload();
            }).catch(error => {
                this.loginFailed = true;
                //console.log("Login failed")
                this.loginIsActive = false;
                //console.log(error, error.response, error.response.data, error.response.data.errors, error.response.data.errors.state);
                if (error.response.data.errors.status && error.response.data.errors.status == 'pending') {
                    this.use_two_factor = true;
                }
            });
        }
    }

}
</script>